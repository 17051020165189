/* Specific variables */

$mediumGray: #81889c;
$lowGray: #aeaeae;
$positiveGreen: #52842b;
$positiveBlue: #20367a;
$dangerRed: #ce2f1a;
$customLineHight: 18px;
$seaBlue: #365bca;
$smoothGray: #c7c7c7;
$backgroundBlueMsg: #DDE4F6;
$backgroundGreenMsg: #E5F3DA;
$alertWarningBackground: #FEF6D6;

/* Global styles */

// Vertical divider
.order-divider-y {
    height: 32px;
    width: 1px;
    background: $softGray;
}

.order-divider-y__flex {
    margin: 0 16px 16px 16px;
    align-self: stretch;
    width: 1px;
    background: $backgroundBlueMsg;
}

// Horizontal divider
.order-divider-x {
    width: 100%;
    background: $backgroundBlueMsg;
    height: 1px;
}

.order-details-divider-x {
    width: 100%;
    height: 16px;
    background-color: #f6f6f6;
    position: absolute;
    left: 0;
}

/* OrderHistory & ShippingHistory */

.order-histoy-container {
    .order__history__header {
        background-color: $white;
        padding: 16px;

        .header__title {
            padding-bottom: 16px;
            h1 {
                font-family: $titleFont;
                font-size: 20px;
                font-weight: 600;
                color: $primaryGray;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }

        .header__subtitle {
            padding-bottom: 16px;

            h4 {
                font-family: $titleFont;
                font-size: 16px;
                font-weight: 600;
                color: $primaryGray;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }

        &--filter-tags {
            line-height: 16px;

            .filters__tag {
                padding-top: 24px;

                .filter__tag {
                    display: inline-block;
                    background-color: #eef2fe;
                    color: $seaBlue;
                    font-size: 14px;
                    font-weight: 400;
                    padding: 4px 0 4px 8px;
                    margin-bottom: 6px;

                    &--close {
                        font-size: 12px;
                        button {
                            background-color: transparent;
                            border: 0;
                            outline: 0;
                            color: $seaBlue;
                            font-weight: 600;
                            padding-left: 8px;
                            padding-right: 8px;

                            &:hover {
                                color: $primaryColor;
                            }
                        }
                    }

                    &:nth-child(1n + 1) {
                        margin-right: 6px;
                    }
                }

                &--clear-all {
                    cursor: pointer;
                    font-size: 14px;
                    color: $primaryColor;
                    font-weight: 500;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .header__tabs {
        display: inline-block;
        .header__tabs__buttons {
            display: flex;
            align-items: center;

            .tab__link {
                display: flex;
                align-items: center;
                gap: 6px;
                padding: 10px;
                border-bottom: 2px solid $softGray;
                cursor: pointer;

                &:hover {
                    background-color: $primaryColorHover;
                }

                svg {
                    path {
                        fill: #000000;
                    }
                }

                span {
                    font-size: 14px;
                    font-weight: 500;
                    color: $primaryColor;
                }
            }

            .tab__link.active {
                border-bottom: 2px solid $normalblue;

                svg {
                    path {
                        fill: $normalblue;
                    }
                }

                span {
                    color: $normalblue;
                }
            }
        }
    }

    .header__filters__heading {
        h4 {
            font-family: $titleFontNormal;
            color: $primaryGray;
            font-weight: 600;
            font-size: 14px;
            margin: 0;
            padding: 24px 0 0 0;
        }
    }

    .header__filters {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 10px;

        .header__filters__buttons {
            display: flex;
            align-items: center;
            gap: 16px;

            &--btn {
                button {
                    padding: 6px 16px;
                    background-color: #f9f9f9;
                    color: $primaryColor;
                    font-size: 14px;
                    font-weight: 500;
                    border-radius: 8px;
                    border: 1px solid transparent;
                    outline: 0;

                    &:hover {
                        background-color: $primaryColorHover;
                    }
                }

                @media (max-width: $mdMaxWidth) {
                    display: inline-block;

                    &:nth-child(1n + 1) {
                        margin-right: 16px;
                    }
                }
            }

            &--btn.active {
                button {
                    border: 1px solid $seaBlue;
                    color: $seaBlue;
                    background-color: $white;

                    &:hover {
                        background-color: $primaryColorHover;
                    }
                }
            }

            &--btn-more {
                display: inline-block;
                
                span {
                    font-size: 14px;
                    font-weight: 500;
                }

                .bt {
                    border: 1px solid transparent;
                }

                &:hover {
                    background-color: $primaryColorHover;
                }

                @media (max-width: $mdMaxWidth) {
                    display: none;
                }
            }

            @media (max-width: $mdMaxWidth) {
                overflow: auto !important;
                white-space: nowrap;
                display: inline-block;
                padding-bottom: 10px;
            }
        }

        .filters__search__desktop {
            width: 240px;

            @media (max-width: 1398px) {
                display: none;
            }
        }
    }

    .filters__search__responsive {
        display: none;
        padding-top: 24PX;

        .flex__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 6px;

            &--input {
                width: 100%;
            }

            &--btn {
                display: none;

                .bt {
                    border: 0;
                    padding: 8px 12px;
                    i {
                        font-size: 16px;
                        margin: 0;
                    }
                    span {
                        display: none;
                    }
                }

                @media (max-width: $mdMaxWidth) {
                    display: block;
                }
            }
        }

        @media (max-width: 1398px) {
            display: block;
        }

        @media (max-width: $mdMaxWidth) {
            padding-top: 14px;
        }
    }

    .order__card {
        margin-top: 18px;
        background-color: $white;

        .order__card__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px;

            &--btn {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                .bt {
                    display: inline-block;
                }

                @media (max-width: $smMaxWidth) {
                    display: none;
                }
            }

            .order__card__header__info {
                display: flex;
                align-items: baseline;

                .card__header__text {
                    display: flex;
                    align-items: baseline;
                    flex-direction: column;

                    &--title {
                        font-size: 14px;
                        font-weight: 500;
                        color: $primaryColor;
                    }

                    &--subtitle {
                        font-size: 14px;
                        font-weight: 400;
                        color: $primaryColor;
                    }

                    &:nth-child(1n + 1) {
                        margin-right: 32px;
                    }

                    &:nth-last-child(1) {
                        margin-right: 0;
                    }

                    @media (max-width: $smMaxWidth) {
                        &:first-child {
                            width: 100%;
                        }

                        &:nth-child(1n + 1) {
                            margin-right: 16px;
                        }

                        &:nth-last-child(1) {
                            margin-right: 0;
                        }

                        &:first-child {
                            margin-right: 0;
                        }
                    }
                }

                @media (max-width: $smMaxWidth) {
                    display: flex;
                    flex-wrap: wrap;
                }
            }

            @media (max-width: $smMaxWidth) {
                .order__card__header__info .card__header__text:nth-child(1) {
                    padding-bottom: 6px !important;
                }
            }
        }

        .order__card__item--container {
            padding: 0 16px;

            .order__card__item--subcontainer {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin-top: 16px;

                .show-more-less-shipping > div:nth-child(1n + 4) {
                    display: none;
                }

                .w-100 div div > div:first-of-type {
                    .order-divider-x {
                        display: none;
                    }

                    .order__card__item {
                        padding-top: 0;
                    }

                    @media (max-width: $smMaxWidth) {
                        .order-divider-x {
                            display: block;
                            margin: 16px 0;
                        }
                    }
                }

                @media (max-width: $smMaxWidth) {
                    flex-direction: column-reverse;

                    .order-divider-y__flex {
                        display: none;
                    }
                }
            }

            .order__card__item {
                background-color: $white;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px 0;
                line-height: $customLineHight;

                .qty {
                    font-size: 12px;
                    font-weight: 400;
                    color: $primaryGray;
                }

                .card__item__productinfo {
                    display: flex;
                    align-items: flex-start;
                    gap: 24px;

                    &--container {
                        display: flex;
                        align-items: baseline;
                        flex-direction: column;
                        gap: 6px;
                    }

                    &--img {
                        width: 120px;
                        height: 96px;
                        object-fit: cover;
                        position: relative;

                        img {
                            width: 120px;
                            height: 96px;
                            object-fit: cover;

                            @media (max-width: $smMaxWidth) {
                                width: 104px;
                                height: 90px;
                            }
                        }

                        .is-img-configurable {
                            object-fit: contain;
                        }

                        .product-image {
                            background-size: cover;
                            padding: 2px;
                        }

                        @media (max-width: $smMaxWidth) {
                            width: 104px;
                            height: 90px;
                        }
                    }

                    &--text {
                        .brand-container {
                            padding-bottom: 4px;
                        }

                        .brand {
                            font-size: 11px;
                            font-weight: 300;
                            color: $primaryColor;
                        }

                        .product {
                            span {
                                font-size: 12px;
                                font-family: $infoFont;
                                font-weight: 500;
                                color: $primaryColor;
                                margin: 0;
                            }
                        }

                        .technical {
                            font-size: 12px;
                            font-weight: 300;
                            color: $primaryGray;
                        }

                        .lot-id {
                            span {
                                font-size: 12px;
                                font-weight: 400;
                                color: $primaryGray;
                            }
                        }

                        .product-id {
                            padding: 4px 0;
                            span {
                                font-size: 12px;
                                font-weight: 400;
                                color: $primaryGray;
                            }
                        }

                        .cts-custom-mobiliario,
                        .cts-product-id,
                        .cts-brand,
                        .cts-color,
                        .cts-finish,
                        .cts-addon,
                        .cts-shape,
                        .cts-diameter,
                        .cts-format,
                        .cts-radius,
                        .cts-edge,
                        .cts-bevel,
                        .cts-packaging,
                        .cts-thickness,
                        .cts-pattern,
                        .price {
                            span {
                                font-size: 12px;
                                font-weight: 400;
                                color: $primaryColor;
                            }
                        }

                        .price {
                            padding-top: 4px;
                        }

                        .indicators-block {
                            flex-wrap: wrap;
                            display: flex;
                            align-items: baseline;
                            grid-gap: 4px;
                            gap: 4px;
                        }

                        @media (min-width: 768px) and (max-width: 991px) {
                            width: 248px;
                        }

                        @media (min-width: 992px) {
                            width: 290px;
                        }
                    }

                    @media (max-width: $smMaxWidth) {
                        gap: 16px;
                    }
                }

                .card__item__segmentation--desktop {
                    ul {
                        margin: 0;
                        li {
                            span,
                            strong {
                                font-size: 12px;
                                font-weight: 400;
                                color: $primaryGray;
                            }
                        }
                    }

                    @media (max-width: $smMaxWidth) {
                        padding: 6px 0;
                    }

                    @media (min-width: $mdMinWidth) and (max-width: $mdMaxWidth) {
                        display: none;
                    }
                }

                .card__item__segmentation--tablet {
                    display: none;

                    ul {
                        margin: 0;
                        li {
                            span,
                            strong {
                                font-size: 12px;
                                font-weight: 400;
                                color: $primaryGray;
                            }
                        }
                    }

                    @media (min-width: $mdMinWidth) and (max-width: $mdMaxWidth) {
                        display: block;
                    }
                }

                &--shipping-content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    .desktop-screen {
                        @media (max-width: $smMaxWidth) {
                            display: none;
                        }
                    }

                    .mobile-screen {
                        display: none;
                        @media (max-width: $smMaxWidth) {
                            display: block;
                        }
                    }
                }

                @media (max-width: $smMaxWidth) {
                    flex-direction: column;
                    align-items: baseline;
                    padding: 16px 24px;
                }
            }

            @media (min-width: 768px) {
                .order__card__item__grid {
                    display: grid;
                    column-gap: 24px;
                    grid-template-columns: repeat(2, 1fr);
                    align-items: flex-start;
                }
            }

            @media (min-width: 992px) {
                .order__card__item__grid {
                    display: grid;
                    column-gap: 24px;
                    grid-template-columns: repeat(3, 1fr);
                    align-items: center;
                }
            }

            // Slick Slider
            .slick-slider {
                .slick-dots {
                    position: relative;
                    bottom: 0;
                    padding-bottom: 16px;

                    li {
                        button:before {
                            font-size: 8px;
                        }
                    }

                    li.slick-active {
                        button:before {
                            opacity: 1;
                            font-size: 14px;
                        }
                    }
                }
            }

            .show__more__less {
                padding: 16px 0;
                border-top: 1px solid $backgroundBlueMsg;

                button {
                    display: block;
                    width: 100%;
                    padding: 4px 18px;
                    border: 1px solid $primaryColor;
                    border-radius: 0;
                    background-color: transparent;
                    color: $primaryColor;
                    font-weight: 500;
                    outline: 0;

                    &:hover {
                        background-color: $primaryColorHover;
                        color: $primaryColor;
                    }

                    i {
                        margin-left: 8px;
                    }
                }
            }

            .show-more-less-order > div:nth-child(1n + 4) {
                display: none;
            }

            @media (max-width: $smMaxWidth) {
                padding: 0 12px;
            }
        }

        .order__card__footer {
            display: none;
            padding: 16px;
            border-top: 1px solid $backgroundBlueMsg;

            @media (max-width: $smMaxWidth) {
                display: block;
            }
        }
    }

    .position__status {
        display: flex;
        align-items: center;
        gap: 6px;

        &--text {
            font-size: 14px;
            font-weight: 400;
        }

        // Colors by orderlines status

        .position__status--shipping-icon.--position-delivered {
            svg {
                path {
                    fill: $positiveGreen;
                }
            }
        }
        .position__status--shipping-icon.--position-partially-delivered {
            svg {
                path {
                    fill: $positiveBlue;
                }
            }
        }
        .position__status--shipping-icon.--position-in-process {
            svg {
                path {
                    fill: $positiveBlue;
                }
            }
        }
        .position__status--shipping-icon.--position-pending {
            svg {
                path {
                    fill: $positiveBlue;
                }
            }
        }
        .position__status--shipping-icon.--position-confirmed {
            svg {
                path {
                    fill: $positiveBlue;
                }
            }
        }
        .position__status--shipping-icon.--position-created {
            svg {
                path {
                    fill: $positiveBlue;
                }
            }
        }
        .position__status--shipping-icon.--position-cancelled {
            svg {
                path {
                    fill: $dangerRed;
                }
            }
        }

        // Text colors by orderlines status

        .position__status--text.--position-delivered {
            color: $positiveGreen;
        }

        .position__status--text.--position-partially-delivered {
            color: $positiveBlue;
        }

        .position__status--text.--position-in-process {
            color: $positiveBlue;
        }
        .position__status--text.--position-pending {
            color: $positiveBlue;
        }
        .position__status--text.--position-confirmed {
            color: $positiveBlue;
        }
        .position__status--text.--position-created {
            color: $positiveBlue;
        }
        .position__status--text.--position-cancelled {
            color: $dangerRed;
        }

        @media (max-width: $smMaxWidth) {
            margin-bottom: 8px;
        }
    }

    /* Order & Shipping Modal Filter */

    .order__shipping__filter__modal {
        .modal-dialog {
            max-width: 1020px !important;
            .modal-content {
                padding: 16px 24px;
                .modal-header {
                    padding: 10px 0;

                    h5 {
                        color: $primaryColor;
                        font-family: $titleFontRegular;
                        font-size: 16px;
                        font-weight: 600;
                    }

                    button {
                        right: 0;
                        margin: 0;
                        top: 0;
                        padding: 0;
                    }
                }

                .modal-body {
                    padding: 0;
                    max-height: 100%;

                    .filter__body__content {
                        .filter__label__title {
                            padding-top: 24px;
                            h5 {
                                font-family: $titleFontRegular;
                                color: $primaryColor;
                                font-size: 20px;
                                font-weight: 600;
                                margin: 0;
                            }
                        }

                        .filter__row__content {
                            padding: 24px 0 24px 0;
                            display: flex;
                            border-bottom: 1px solid #e2e4e9;

                            &--track-list {
                                overflow: auto;
                                white-space: nowrap;
                                display: inline-block;
                                padding-bottom: 10px;

                                .order__shipping__date__section {
                                    display: flex;
                                    align-items: end;
                                    gap: 16px;

                                    &--select {
                                        width: 220px;
                                        border: 1px solid $primaryColor;
                                        font-size: 14px;
                                        font-weight: 300;
                                    }

                                    &--dates {
                                        display: flex;
                                        align-items: center;
                                        gap: 10px;

                                        .from,
                                        .to {
                                            display: flex;
                                            flex-direction: column;
                                            align-items: flex-start;
                                        }

                                        label {
                                            font-size: 14px;
                                            font-weight: 300;
                                            color: $primaryColor;
                                            margin-bottom: 6px;
                                        }

                                        .react-datepicker-wrapper {
                                            max-width: 140px;
                                            font-size: 14px;
                                            font-weight: 300;
                                            color: $primaryColor;
                                        }
                                    }

                                    @media (max-width: $smMaxWidth) {
                                        flex-direction: column;
                                        align-items: flex-start;
                                    }
                                }

                                .filter__button {
                                    padding: 6px 16px;
                                    background-color: #f9f9f9;
                                    color: $primaryColor;
                                    font-size: 14px;
                                    font-weight: 500;
                                    border-radius: 8px;
                                    border: 1px solid transparent;
                                    outline: 0;

                                    &:hover {
                                        background-color: $primaryColorHover;
                                    }
                                }

                                .filter__button.is-selected {
                                    border: 1px solid $seaBlue;
                                    color: $seaBlue;
                                    background-color: #ffffff;

                                    &:hover {
                                        background-color: $primaryColorHover;
                                    }
                                }

                                .filter__button:nth-child(1n + 1) {
                                    margin-right: 16px;
                                }
                            }
                        }

                        &--footer {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            gap: 24px;
                            margin-top: 24px;

                            span {
                                display: block;
                            }

                            .clean-filters {
                                border: 1px solid $primaryColor;
                            }

                            @media (max-width: $smMaxWidth) {
                                justify-content: center;

                                .bt {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }

                .filter__body__content .filter__row__content:nth-child(2) .filter__row__content--track-list {
                    overflow: visible;
                }
                .filter__body__content .filter__row__content:nth-child(2) {
                    overflow: visible;
                }
            }
        }
    }

    .orders__not__results {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 16px;
        padding-top: 24px;

        h5 {
            font-size: 14px;
            font-weight: 500;
            color: $primaryColor;
            margin: 0;
            padding: 0;
            text-align: center;
        }

        button {
            padding: 6px 16px;
            background-color: $primaryColor;
            color: $white;
            font-size: 12px;
            font-weight: 500;
            border: 1px solid transparent;
            outline: 0;

            &:hover {
                background-color: $primaryColorHover;
                color: $primaryColor;
            }
        }
    }
}

// Alert
.order__alert__warning {
    border-radius: 0;
    background-color: $alertWarningBackground;
    border: 0;
    color: $primaryColor;
    font-size: 14px;
    font-weight: 400;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 8px 10px;
}

.timeline__order__status {
    display: inline-block;
    padding: 4px 8px;
    line-height: 15px;

    @media (min-width: $lgMinWidth) {
        margin-top: 24px;
    }
}

.timeline__order__status.--position-created,
.timeline__order__status.--position-confirmed,
.timeline__order__status.--position-pending,
.timeline__order__status.--position-in-process,
.timeline__order__status.--position-partially-delivered {
    background-color: $backgroundBlueMsg;

    .order__status--dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
        background-color: $positiveBlue;
        margin-right: 8px;
    }

    .order__status--status,
    .order__status--text {
        color: $positiveBlue;
        font-weight: 400;
        font-size: 14px;
    }
}

.timeline__order__status.--position-delivered {
    background-color: $backgroundGreenMsg;

    .order__status--dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
        background-color: $positiveGreen;
        margin-right: 8px;
    }

    .order__status--status,
    .order__status--text {
        color: $positiveGreen;
        font-weight: 400;
        font-size: 14px;
    }
}

.timeline__order__status.--position-cancelled,
.timeline__order__status.--position-changes {
    background-color: #FBDFDC;

    .order__status--dot {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
        background-color: $dangerRed;
        margin-right: 8px;
    }

    .order__status--status,
    .order__status--text {
        color: $dangerRed;
        font-weight: 400;
        font-size: 14px;
    }
}

.timeline__delivery__status {
    display: inline-block;
    padding: 4px 8px;
    line-height: 15px;

    @media (min-width: $lgMinWidth) {
        margin-top: 24px;
    }
}

.timeline__delivery__status.--status-treated-partially, .timeline__delivery__status.--status--not-treated {
    background-color: $backgroundBlueMsg;

    span {
        svg {
            margin-right: 8px;
            path {
                fill: $positiveBlue;
            }
        }
    }

    .timeline__delivery__status__text {
        color: $positiveBlue;
        font-weight: 400;
        font-size: 14px;
    }
}

.timeline__delivery__status.--status--treated--completelly {
    background-color: $backgroundGreenMsg;

    span {
        svg {
            margin-right: 8px;
            path {
                fill: $positiveGreen;
            }
        }
    }

    .timeline__delivery__status__text {
        color: $positiveGreen;
        font-weight: 400;
        font-size: 14px;
    }
}


/* OrderDetails & ShippingDetails */

.order__shipping__details {
    .order__shipping__details__header {
        padding: 16px;
        background-color: $white;
        margin-bottom: 16px;

        &--goback {
            display: inline-block;
            border-bottom: 1px solid transparent;
            margin-bottom: 24px;
            cursor: pointer;

            i {
                color: $normalblue;
                font-size: 12px;
                margin-right: 8px;
            }

            a {
                font-size: 14px;
                font-weight: 500;
                color: $normalblue;
            }

            &:hover {
                border-bottom: 1px solid $normalblue;
            }
        }

        &--title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 24px;

            .main-title {
                h1 {
                    font-size: 20px;
                    font-family: 'diagramm';
                    color: $primaryGray;
                    margin: 0;
                }
            }
        }

        .details-btn-group {
            span {
                font-size: 14px;
                font-weight: 500;
                color: $normalblue;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            i {
                font-size: 12px;
                color: $normalblue;
            }

            button {
                display: flex;
                align-items: center;
                background-color: transparent;
                border: 0;

                i {
                    margin-right: 8px;
                }

                svg {
                    margin-right: 8px;
                }

                .download-invoice {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                }
            }

            .desktop-screen {
                display: flex;
                align-items: center;
                gap: 24px;
            }

            .mobile-screen {
                display: flex;
                align-items: baseline;
                justify-content: center;
                flex-direction: column;
                padding-top: 24px;
                gap: 12px;
            }
        }
    }

    .order__shipping__boxresume__title {
        font-size: 16px;
        font-weight: 500;
        color: $primaryGray;
        padding-top: 16px;
        padding-bottom: 16px;
        margin-bottom: 0;

        @media (max-width: 575px) {
            padding-left: 16px;
        }
    }

    .order__shipping__boxcontent__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 16px;
        padding-bottom: 16px;

        h3 {
            font-size: 16px;
            font-weight: 500;
            color: $primaryGray;
            margin-bottom: 0;
        }

        .edit-positions {
            display: inline-block;
            color: $primaryColor;
            cursor: pointer;
            padding: 5px 20px;
            text-decoration: none;
            border-radius: 0;
            border: 1px solid $primaryColor;
            background-color: $white;
            text-align: center;
            outline: 0;

            i {
                font-size: 16px;
                margin-right: 8px;
            }

            span {
                font-size: 14px;
                font-weight: 500;
                font-family: $infoFont;
                color: $primaryColor;
            }

            &:hover {
                background-color: $primaryColorHover;
            }
        }

        .discard-changes {
            display: inline-block;
            color: $primaryColor;
            cursor: pointer;
            padding: 5px 20px;
            text-decoration: none;
            border-radius: 0;
            border: 1px solid $primaryColor;
            background-color: $white;
            text-align: center;
            outline: 0;

            span {
                font-size: 14px;
                font-weight: 500;
                font-family: $infoFont;
                color: $primaryColor;
            }

            &:hover {
                background-color: $primaryColorHover;
            }
        }

        .save-changes {
            display: inline-block;
            color: $white;
            cursor: pointer;
            padding: 5px 20px;
            text-decoration: none;
            border-radius: 0;
            border: 1px solid $primaryColor;
            background-color: $primaryColor;
            text-align: center;
            outline: 0;

            span {
                font-size: 14px;
                font-weight: 500;
                font-family: $infoFont;
                color: $white;
            }

            &:hover {
                background-color: $primaryColorHover;
                color: $primaryColor;
            }

            &:disabled {
                background-color: #d8d8d8;
                border: 1px solid #d8d8d8;
                color: $lowGray;
            }
        }

        &--edit {
            @media (max-width: $smMaxWidth) {
                padding-top: 16px;
            }
        }

        &--btn-actions {
            display: flex;
            align-items: center;
            gap: 16px;

            @media (max-width: $smMaxWidth) {
                padding-top: 16px;
            }
        }

        @media (max-width: $smMaxWidth) {
            display: block;
        }

        @media (max-width: 575px) {
            padding-left: 16px;
        }
    }

    .order__shipping__details__boxresume {
        padding: 16px;
        padding-bottom: 16px;
        margin-top: 0;
        margin-bottom: 16px;
        background-color: $white;

        &--title {
            h4 {
                font-size: 14px;
                font-weight: 500;
                color: $primaryGray;
                padding-bottom: 16px;
                margin: 0;
            }
        }

        &--content {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 32px;
            padding-top: 16px;
            padding: 16px 0;

            span {
                font-size: 12px;
                font-weight: 500;
                color: $primaryGray;
                margin-bottom: 8px;
            }

            @media (max-width: $smMaxWidth) {
                display: flex;
                flex-direction: column;
                align-items: baseline;
                gap: 16px;
            }
        }
        
        .shipping-view {
            padding-bottom: 0;
        }

        &--comments {
            padding-top: 16px;
            color: $primaryGray;

            .title {
                font-size: 12px;
                font-weight: 500;
            }

            .comment {
                font-size: 12px;
                font-weight: 400;
            }
        }
    }

    .edit-mode-table {
        border: 1px solid $normalblue;

        .order-details-divider-x {
            @media (max-width: 767px) {
                border-top: 1px solid $normalblue;
                border-bottom: 1px solid $normalblue;
            }
        }
    }

    .order__shipping__details__table {
        background-color: $white;
        padding: 16px;

        &--table {
            width: 100%;

            tbody {
                line-height: $customLineHight;
                tr {
                    vertical-align: top;
                    border-top: 1px solid $backgroundBlueMsg;
                    transition: background-color 0.5s ease-out;

                    @media (max-width: $smMaxWidth) {
                        border: 0;
                    }

                    .order-details-divider-x {
                        display: none;

                        @media (max-width: $smMaxWidth) {
                            display: block;
                        }
                    }
                }

                td {
                    padding: 16px 0;
                    color: $primaryColor;
                }

                .td-description {
                    .d-block-only-mobile {
                        display: none;
                        @media (max-width: $smMaxWidth) {
                            display: block;
                        }
                    }
                }

                .td-additional-info {
                    @media (max-width: $mdMaxWidth) {
                        display: none;
                    }
                }

                .td-delivery-info {
                    @media (max-width: $smMaxWidth) {
                        display: none;
                    }
                }

                .td-position-actions {
                    text-align: right;
                    @media (max-width: $smMaxWidth) {
                        display: none;
                    }
                }
            }

            tbody > tr:first-of-type {
                .order-details-divider-x {
                    visibility: hidden;
                }

                .order__shipping__details__table--description {
                    margin-top: 0;
                }
            }

            .edit-mode-position-number {
                display: flex;
                justify-content: flex-end;

                .input-number {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 3px;
                    max-width: 100%;
                    color: $primaryColor;
                    text-align: center;

                    .input-container {
                        cursor: pointer;
                        width: 48px;
                        height: 32px;
                        border: 1px solid $primaryColor;

                        input {
                            background-color: $white;
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }

                    .spin {
                        background-color: $white;
                        cursor: pointer;
                        height: 32px;
                        width: 35px;
                        border: 1px solid $primaryColor;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 14px;

                        &:hover {
                            background-color: $primaryColorHover;
                        }
                    }
                }

                @media (max-width: $smMaxWidth) {
                    padding-top: 6px;
                }
            }

            .edit-mode-reject-pos {
                text-align: right;
                padding-top: 16px;
                margin-right: 4px;
                button {
                    background-color: $white;
                    border: 1px solid transparent;
                    color: $primaryColor;
                    font-size: 12px;
                    font-weight: 500;
                    outline: 0;

                    i {
                        margin-right: 8px;
                    }
                    &:hover {
                        background-color: $primaryColorHover;
                    }
                }
            }

            .edit-mode-undo-pos {
                text-align: right;
                padding-top: 16px;
                margin-right: 4px;
                button {
                    background-color: $white;
                    border: 1px solid transparent;
                    color: $primaryColor;
                    font-size: 12px;
                    font-weight: 500;
                    outline: 0;

                    i {
                        margin-right: 8px;
                    }

                    &:hover {
                        background-color: $primaryColorHover;
                    }
                }
            }

            .position-rejected {
                background-color: #fbdfdc;
                transition: background-color 0.5s ease;

                .td-position-actions {
                    .edit-mode-position-number {
                        display: none;
                    }
                }

                .td-description {
                    .edit-mode-position-number {
                        display: none;
                    }
                }
            }
        }

        &--heading {
            th {
                font-size: 12px;
                font-weight: 500;
                color: $primaryColor;
                text-align: left;
                padding-bottom: 6px;
            }

            @media (max-width: $smMaxWidth) {
                display: none;
            }

            .th-addional-info {
                @media (max-width: $mdMaxWidth) {
                    display: none;
                }
            }
        }

        &--description {
            display: flex;
            align-items: flex-start;
            gap: 16px;

            .card__item__productinfo--container {
                display: flex;
                align-items: baseline;
                flex-direction: column;
                grid-gap: 6px;
                gap: 6px;
            }

            .product-img {
                width: 120px;
                height: 96px;
                object-fit: cover;
                position: relative;

                img {
                    width: 120px;
                    height: 96px;
                    object-fit: cover;
                }

                .is-img-configurable {
                    object-fit: contain;
                }

                .product-image {
                    background-size: cover;
                    padding: 2px;
                }
            }

            .product-description-table {
                .brand-container {
                    padding-bottom: 4px;
                }

                .brand {
                    font-size: 11px;
                    font-weight: 300;
                    color: $primaryColor;
                }

                .product {
                    span {
                        font-size: 12px;
                        font-family: $infoFont;
                        font-weight: 500;
                        color: $primaryColor;
                        margin: 0;
                    }
                }

                .technical {
                    font-size: 12px;
                    font-weight: 300;
                    color: $primaryGray;
                }

                .lot-id {
                    span {
                        font-size: 12px;
                        font-weight: 400;
                        color: $primaryGray;
                    }
                }

                .product-id {
                    padding: 4px 0;
                    span {
                        font-size: 12px;
                        font-weight: 400;
                        color: $primaryGray;
                    }
                }

                .indicators-block {
                    flex-wrap: wrap;
                    display: flex;
                    align-items: baseline;
                    grid-gap: 4px;
                    gap: 4px;
                }

                .qty {
                    font-size: 12px;
                    font-weight: 400;
                    color: $primaryGray;
                }

                .cts-custom-mobiliario,
                .cts-product-id,
                .cts-brand,
                .cts-color,
                .cts-finish,
                .cts-addon,
                .cts-shape,
                .cts-diameter,
                .cts-format,
                .cts-radius,
                .cts-edge,
                .cts-bevel,
                .cts-packaging,
                .cts-thickness,
                .cts-pattern,
                .price {
                    span {
                        font-size: 12px;
                        font-weight: 400;
                        color: $primaryColor;
                    }
                }

                .price {
                    padding-top: 4px;
                }

                .mall-logo {
                    img {
                        max-width: 48px;
                    }
                }
            }

            /* DisplayOutletIndicator */
            .outlet-indicator {
                background: #d66f00;
                border-radius: 2px;
                color: $white;
                font-size: 10px;
                font-weight: bold;
                padding: 1px 2px;
                display: inline-block;
                text-transform: uppercase;
            }

            .additional-mobile {
                ul {
                    margin: 0;
                    li {
                        span,
                        strong {
                            font-size: 12px;
                            font-weight: 400;
                            color: $primaryGray;
                        }
                    }
                }

                span {
                    font-size: 12px;
                    font-weight: 400;
                    color: $primaryGray;
                }

                @media (max-width: $mdMaxWidth) {
                    padding-top: 16px;
                }
            }

            @media (max-width: $mdMaxWidth) {
                align-items: flex-start;
            }

            @media (max-width: $smMaxWidth) {
                padding-bottom: 6px;
                margin-top: 16px;
            }

            .d-block-only-tablet {
                display: none;
                @media only screen and (max-width: $mdMaxWidth) and (min-width: 768px) {
                    display: block;
                    padding-top: 16px;
                }
            }

            @media (max-width: $smMaxWidth) {
                .product-img {
                    width: 104px;
                    height: 90px;

                    img {
                        width: 104px;
                        height: 90px;
                    }
                }
            }
        }

        &--additional {
            ul {
                padding-bottom: 6px;
                margin: 0;
                li {
                    span,
                    strong {
                        font-size: 12px;
                        font-weight: 400;
                        color: $primaryGray;
                    }
                }
            }

            span {
                font-size: 12px;
                font-weight: 400;
                color: $primaryGray;
            }

            .comments {
                padding-bottom: 6px;
            }

            .individual-preferred-delivery-date {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 6px;

                .input-date {
                    width: 40%;
                    .preferred-date-input {
                        border-color: $primaryColor;
                        font-size: 14px;
                        font-family: $infoFont;
                        color: $primaryColor;
                    }
                }
            }

            @media (max-width: $smMaxWidth) {
                padding: 6px 0;
            }
        }

        &--shippings {
            display: flex;
            align-items: baseline;
            flex-direction: column;

            .shipping__date__url {
                width: 100%;

                &--content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    span {
                        font-size: 12px;
                        font-weight: 400;
                        color: $primaryColor;
                    }

                    .delivery-id-column {
                        border-left: 1px solid $smoothGray;
                        padding-left: 6px;

                        button {
                            background-color: transparent;
                            border: 0;
                            padding: 0;
                            outline: 0;
                            font-size: 12px;
                            font-weight: 500;
                            color: $normalblue;
                            text-align: left;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            @media (max-width: $mdMaxWidth) {
                align-items: flex-start;
            }
        }

        .product__cancelled__basic {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 0 8px;
            background-color: #fbdfdc;

            @media (max-width: $smMaxWidth) {
                margin-top: 6px;
            }

            .dot-icon {
                height: 10px;
                width: 10px;
                border-radius: 50%;
                display: inline-block;
                background-color: $dangerRed;
            }

            span {
                font-size: 12px;
                font-weight: 400;
                color: $dangerRed;
            }
        }

        .product__modified {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 0 8px;
            margin-bottom: 6px;
            background-color: $backgroundBlueMsg;

            @media (max-width: $smMaxWidth) {
                margin-top: 6px;
            }

            .dot-icon {
                height: 10px;
                width: 10px;
                border-radius: 50%;
                display: inline-block;
                background-color: $primaryColor;
            }

            span {
                font-size: 12px;
                font-weight: 400;
                color: $primaryColor;
            }
        }

        .product__added {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 0 8px;
            margin-bottom: 6px;
            background-color: $backgroundBlueMsg;

            @media (max-width: $smMaxWidth) {
                margin-top: 6px;
            }

            .dot-icon {
                height: 10px;
                width: 10px;
                border-radius: 50%;
                display: inline-block;
                background-color: $primaryColor;
            }

            span {
                font-size: 12px;
                font-weight: 400;
                color: $primaryColor;
            }
        }

        .product__cancelled {
            &--icon-text {
                display: inline-block;
                padding: 4px 8px;
                line-height: 0;
                background-color: #fbdfdc;

                @media (max-width: $smMaxWidth) {
                    margin-top: 6px;
                }

                .dot-icon {
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: 8px;
                    background-color: $dangerRed;
                }

                span {
                    font-size: 12px;
                    font-weight: 400;
                    color: $dangerRed;
                }
            }

            &--comment {
                span {
                    color: $mediumGray;
                    font-size: 12px;
                    font-style: italic;
                    font-weight: 400;
                    font-weight: 400;
                    color: $primaryColor;
                }

                .delivery-id-column {
                    border-left: 1px solid $smoothGray;
                    padding-left: 6px;

                    button {
                        background-color: transparent;
                        border: 0;
                        padding: 0;
                        outline: 0;
                        font-size: 12px;
                        font-weight: 500;
                        color: $normalblue;
                        text-align: left;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    font-weight: 400;
                    color: $primaryColor;
                }

                .delivery-id-column {
                    border-left: 1px solid $smoothGray;
                    padding-left: 6px;

                    button {
                        background-color: transparent;
                        border: 0;
                        padding: 0;
                        outline: 0;
                        font-size: 12px;
                        font-weight: 500;
                        color: $normalblue;
                        text-align: left;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        &--order {
            display: flex;
            align-items: center;

            span {
                font-size: 12px;
                font-weight: 400;
                color: $primaryGray;
            }

            button {
                background-color: transparent;
                color: $normalblue;
                font-size: 12px;
                font-weight: 500;
                border: 0;
                padding: 0;

                &:hover {
                    text-decoration: underline;
                }
            }

            @media (max-width: $smMaxWidth) {
                padding: 6px 0;
            }
        }

        .lotid__editmode,
        .bundleid__editmode {
            font-size: 12px;
            font-weight: 400;
            color: $primaryGray;
        }

        &--global-preferred-delivery-date {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            padding-bottom: 6px;

            .label-text {
                font-weight: 500;
                font-size: 12px;
                color: $primaryColor;
            }

            .input-date {
                .preferred-date-input {
                    border-color: $primaryColor;
                    font-size: 14px;
                    font-family: $infoFont;
                    color: $primaryColor;
                }
            }

            @media (max-width: $smMaxWidth) {
                padding-top: 16px;
            }
        }

        @media (max-width: $smMaxWidth) {
            padding-top: 0;
        }
    }

    .shipping-details {
        padding-top: 0;

        table {
            td {
                vertical-align: middle;
                color: $primaryColor;
            }

            @media (min-width: 992px) {
                .td-description {
                    width: 40%;
                }

                .td-additional-info {
                    width: 30%;

                    .order__shipping__details__table--additional {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .td-delivery-info {
                    width: 30%;
                }
            }
        }

        tbody > tr:first-of-type {
            border-top: 0;
        }
    }

    .modal__invoice__orders {
        .modal-content {
            .modal-header {
                padding: 16px 24px;
                border-bottom: 1px solid #a9aebc;
                align-items: center;

                .modal-title {
                    color: $primaryColor;
                    font-family: $titleFont;
                    font-weight: 600;
                }
            }
            .modal-body {
                padding: 24px 0 0 0;

                .ReactTable .rt-table .rt-thead .rt-tr .rt-th {
                    padding: 12px 16px;
                    background-color: #f3f5fc;
                    text-align: left;
                    color: #3e3e3c;
                    font-size: 14px;
                    font-weight: 400;
                }

                .ReactTable .rt-table .rt-tbody .rt-tr .rt-td {
                    padding: 16px;

                    .invoice-number {
                        font-size: 16px;
                        cursor: pointer;
                        font-weight: 500;
                        color: $normalblue;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    .invoice-date {
                        color: $primaryColor;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
            .modal-footer {
                display: none;
            }
        }
    }

    .weight__limit__excedeed {
        display: inline-block;

        &--content {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 0 8px;
            background-color: #fbdfdc;

            .dot-icon {
                height: 10px;
                width: 10px;
                border-radius: 50%;
                display: inline-block;
                background-color: $dangerRed;
            }

            span {
                font-size: 12px;
                font-weight: 400;
                color: $dangerRed;
            }
        }

        @media (max-width: $smMaxWidth) {
            padding-top: 16px;
        }
        
        @media (max-width: $xsMaxWidth) {
            margin-left: 16px;
        }
    }
}

/*Shipping status indicators */

.shipping__status__content {
    width: 100%;

    &--elements {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
        flex-wrap: wrap;
    }

    .shipping__status__blue {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-bottom: 6px;

        svg {
            path {
                fill: $positiveBlue;
            }
        }

        span {
            font-size: 14px;
            font-weight: 400;
            color: $positiveBlue;

            @media (max-width: $smMaxWidth) {
                font-size: 12px;
            }
        }
    }

    .shipping__status__info {
        display: flex;
        align-items: center;
        gap: 8px;

        span {
            font-size: 12px;
            font-weight: 400;
            color: $primaryGray;

            @media (max-width: $smMaxWidth) {
                font-size: 12px;
            }
        }

        .subtitle {
            display: inline;
            padding-top: 8px;
           
            span {
                color: $mediumGray;

                button {
                    background-color: transparent;
                    padding: 0;
                    outline: 0;
                    border: 0;
                    text-decoration: underline;
                    color: $mediumGray;
                    text-transform: lowercase;

                    &:hover {
                        color: #3b3e47;
                    }
                }
            }
        }
    }

    .shipping__status__gray {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-bottom: 6px;

        svg {
            path {
                fill: $lowGray;
            }
        }

        span {
            font-size: 14px;
            font-weight: 400;
            color: $lowGray;

            @media (max-width: $smMaxWidth) {
                font-size: 12px;
            }
        }
    }

    .shipping__status__green {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-bottom: 6px;

        svg {
            path {
                fill: $positiveGreen;
            }
        }

        span {
            font-size: 14px;
            font-weight: 400;
            color: $positiveGreen;

            @media (max-width: $smMaxWidth) {
                font-size: 12px;
            }
        }
    }

    .shipping__status__red {
        display: flex;
        align-items: center;
        gap: 8px;
        padding-bottom: 6px;

        svg {
            path {
                fill: $dangerRed;
            }
        }

        span {
            font-size: 14px;
            font-weight: 400;
            color: $dangerRed;

            @media (max-width: $smMaxWidth) {
                font-size: 12px;
            }
        }
    }

    .shipping__date__url {
        width: 100%;

        &--content {
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                font-size: 12px;
                font-weight: 400;
                color: $primaryColor;
            }

            .delivery-id-column {
                border-left: 1px solid $smoothGray;
                padding-left: 6px;

                button {
                    background-color: transparent;
                    border: 0;
                    padding: 0;
                    outline: 0;
                    font-size: 12px;
                    font-weight: 500;
                    color: $normalblue;
                    text-align: left;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .shipping__date__url__partially {
        &--container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 6px;

            span {
                font-size: 12px;
                font-weight: 400;
                color: $primaryColor;
            }

            .shipping__status__gray {
                padding-bottom: 0;
            }
        }

        &--shipment {
            border-left: 1px solid $smoothGray;
            padding-left: 6px;

            button {
                background-color: transparent;
                border: 0;
                padding: 0;
                outline: 0;
                font-size: 12px;
                font-weight: 500;
                color: $normalblue;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .partially__see__shippings {
        button {
            display: inline-block;
            background-color: transparent;
            font-size: 12px;
            font-weight: 500;
            color: $normalblue;
            border: 0;
            outline: 0;
            padding: 0;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .delivery__info__modal {
        .modal-body {
            padding-top: 24px;

            .delivery-divider-x {
                width: 100%;
                height: 1px;
                background-color: $primaryColor;
                margin: 16px 0;
            }

            .shipping__date__url__partially {
                @media (min-width: 768px) {
                    width: 60% !important;
                }
            }
        }

        .brand {
            font-size: 11px;
            font-weight: 300;
            color: $primaryColor;
        }

        .product {
            font-size: 12px;
            font-family: 'Roboto', Helvetica, Arial, sans-serif;
            font-weight: 500;
            color: $primaryColor;
            margin: 0;
        }

        .shipping__status__gray {
            padding-bottom: 0;
        }

        .modal-footer {
            display: none;
        }
    }

    @media (max-width: $smMaxWidth) {
        padding-top: 6px;
    }
}

/* This css is neccesary for a clean page to print */
.change-elements-print {
    .order__shipping__details__header .details-btn-group .mobile-screen,
    .order__shipping__details__header--title .details-btn-group .desktop-screen,
    .order__shipping__details__header--goback,
    .order__shipping__details__table--shippings .edit-delete-actions,
    .order-details-divider-x {
        display: none;
    }

    .order__shipping__boxcontent__title--edit,
    .order__shipping__boxcontent__title--btn-actions {
        display: none;
    }

    .order__shipping__details__table--table tbody .td-additional-info,
    .order__shipping__details__table--heading .th-addional-info {
        display: block;
    }

    .shipping-details {
        table {
            table-layout: fixed;
            tr,
            td {
                vertical-align: top;
            }
        }

        tbody > tr:first-of-type {
            border-top: 1px solid $backgroundBlueMsg;
        }
    }
}

.config-bath-indicator {
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #BDE2EC;
    border-radius: 12px;
    padding: 5px 10px;
}

.config-bath-indicator__img {
    margin-right: 2px;

    img {
        width: 18px !important;
        height: auto !important;
    }
}

.config-bath-indicator__text {
    font-family: $infoFont;
    font-weight: 700;
    font-size: 7px;
    color: #365BCA;
    text-transform: uppercase;
    line-height: 0;
}