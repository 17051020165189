.results {
    h1 {
        color: $thunder;
        font-family: $titleFont;
        font-size: 20px;
    }

    .results-list {
        padding: 20px 0 0 0;
        text-align: center !important;


        .slick-list {
            height: 100%;
        }
        .slick-list > * , .slick-slide, .slick-slide > *{
            height: inherit;
        }

        .result {
            display: inline-block;
            margin: 0 12px 20px;
            position: relative;
            text-decoration: none;
            vertical-align: top;
            width: 140px;

            &:not([type='TABLA']) {
                .product-image-container {
                    border-radius: 0;
                    .product-image {
                        background-color: #fff;
                        border-radius: 0;
                    }
                }
            }

            &:hover {
                .product-actions {
                    opacity: 1;
                }
            }

            .outlet-indicator {
                background: #d66f00;
                border-radius: 2px;
                color: $white;
                font-size: 10px;
                font-weight: bold;
                left: -20px;
                padding: 1px 4px 0;
                position: absolute;
                text-transform: uppercase;
                top: -10px;
                z-index: 1;
            }
            .discontinued-indicator {
                background: $discontinuedIndicatorBg;
                border-radius: 2px;
                color: $white;
                font-size: 10px;
                font-weight: bold;
                left: -20px;
                padding: 1px 4px 0;
                position: absolute;
                text-transform: uppercase;
                top: -10px;
                z-index: 1;
            }
            .technology-indicator {
                background: whitesmoke;
                border-radius: 2px;
                box-shadow: rgba($onyx, 0.3) 1px 1px 3px 1px;
                color: $white;
                display: flex;
                font-size: 11px;
                font-weight: bold;
                height: 25px;
                justify-content: center;
                left: -5px;
                padding: 2px;
                position: absolute;
                text-transform: uppercase;
                top: 120px;
                width: 50px;
                z-index: 1;
                &.plus {
                    &:after {
                        color: black;
                        content: '+';
                        font-size: 14px;
                        font-weight: 200;
                        line-height: 20px;
                        margin-left: 2px;
                    }
                }
                > img {
                    width: 80%;
                }
            }
            .promo-indicator {
                background: $promoIndicatorBg;
                border-radius: 2px;
                color: $white;
                font-size: 10px;
                font-weight: bold;
                padding: 1px 4px 0;
                position: absolute;
                right: -20px;
                text-transform: uppercase;
                top: -10px;
                z-index: 1;
            }
            .new-indicator {
                background: #cf4d3f;
                border-radius: 2px;
                color: #ffffff;
                font-size: 12px;
                font-weight: bold;
                padding: 1px 4px 0;
                position: absolute;
                right: -20px;
                border-radius: 100%;
                bottom: 50%;
                z-index: 1;
                width: 50px;
                height: 50px;
                text-align: center;
                display: flex;
                justify-content: center;
                flex-direction: column;
                font-family: $titleFont;
            }
            .product-image-container {
                // background-color: $smoke;
                // background-image: url('../img/icons/no-image.svg');
                // background-position: center;
                // background-repeat: no-repeat;
                // border: 1px dashed $iron;
                border-radius: 50%;
                height: 140px;
                position: relative;

                width: 140px;

                .product-image {
                    background-position: center;

                    background-repeat: no-repeat;
                    background-size: contain;
                    border-radius: 50%;
                    height: 140px;
                    // left: -1px;
                    position: absolute;
                    // top: -1px;
                    width: 140px;
                }
            }

            .product-info {
                text-align: center;

                .product-brand {
                    color: $gray;
                    font-size: 11px;
                    font-weight: 200;
                    margin-top: 10px;
                    text-transform: capitalize;
                }

                .product-color-name {
                    color: $thunder;
                    font-family: $titleFont;
                    -webkit-hyphens: auto;
                    -moz-hyphens: auto;
                    -ms-hyphens: auto;

                    hyphens: auto;
                    line-height: 20px;
                    text-transform: capitalize;
                    -ms-word-break: break-all;

                    word-break: break-all;

                    word-break: break-word;
                }

                .product-info-details {
                    .thickness {
                        display: inline-block;

                        .icon {
                            border-left: 0.5px solid $onyx;
                            border-right: 0.5px solid $onyx;
                            display: inline-block;
                            height: 10px;
                            line-height: 10px;
                            width: 15px;

                            div {
                                background: $onyx;
                                display: inline-block;
                                vertical-align: middle;
                                width: 100%;
                            }
                        }

                        .text {
                            display: inline-block;
                            font-size: 12px;
                            margin-left: 5px;
                            [dir='rtl'] & {
                                margin-left: unset;
                                margin-right: 5px;
                            }
                        }
                    }

                    .finish {
                        display: inline-block;
                        margin-left: 8px;

                        .icon {
                            display: inline-block;
                            vertical-align: middle;

                            img {
                                height: 15px;
                            }
                        }

                        .text {
                            display: inline-block;
                            font-size: 12px;
                            margin-left: 5px;

                            max-width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            vertical-align: middle;
                            white-space: nowrap;
                        }
                    }
                }
            }

            .product-actions {
                opacity: 0;
                text-align: center;

                .action-buttons {
                    margin-top: 5px;
                    text-align: center;

                    .action-button {
                        color: $gray;
                        cursor: pointer;
                        padding: 0 8px;

                        &:not(:last-child) {
                            border-right: 1px solid $gray;
                        }

                        &:hover {
                            i {
                                color: $thunder;
                            }
                        }

                        i {
                            font-weight: 400;
                        }
                    }
                }

                .bt-more-info {
                    border: 1px solid $gray;
                    border-radius: 20px;
                    color: $gray;
                    display: inline-block;
                    font-size: 12px;
                    margin-top: 8px;
                    padding: 3px 20px;
                    text-decoration: none;

                    &:hover {
                        border-color: $thunder;
                        color: $thunder;
                    }
                }
            }
        }
        .product-image-container {
            &__text-info {
                background-color: rgba($onyx, 0.6);
                bottom: 0;
                color: white;
                font-family: $titleFont;
                font-size: 0.8em;
                line-height: 18px;
                overflow: hidden;
                padding: 2px 5px;
                position: absolute;
                text-overflow: ellipsis;
                text-transform: capitalize;
                width: 100%;
            }
        }

        /*SAMPLE*/
        $ancho: 180px;
        $alto: 180px;

        .sample-result {
            display: inline-block;
            margin: 12px;
            position: relative;
            text-align: center;
            text-decoration: none;
            vertical-align: top;
            width: $ancho;

            &:hover {
            }
            .badge {
                background: $onyx;
                position: absolute;
                right: -6px;
                top: -6px;
                z-index: 99;
            }
            .product-image-container {
                align-items: flex-end;
                // background-color: $smoke;
                // background-image: url('../img/icons/no-image.svg');
                // background-position: center;
                // background-repeat: no-repeat;
                // border: 1px dashed $iron;
                display: flex;
                height: $alto;

                position: relative;

                width: $ancho;
                .product-color-name {
                    background-color: rgba($onyx, 0.6);
                    color: white;
                    flex-grow: 1;
                    font-family: $titleFont;
                    -webkit-hyphens: auto;
                    -moz-hyphens: auto;
                    -ms-hyphens: auto;

                    hyphens: auto;
                    line-height: 18px;
                    padding: 2px;
                    text-transform: capitalize;
                    -ms-word-break: break-all;

                    word-break: break-all;

                    word-break: break-word;

                    z-index: 2;
                    .product-brand {
                        color: white;
                        font-family: initial;
                        font-size: 11px;
                        font-weight: 200;
                        text-transform: capitalize;
                    }
                }
                .product-image {
                    background-position: center;

                    background-repeat: no-repeat;
                    background-size: cover;
                    height: $alto;
                    // left: -1px;
                    position: absolute;
                    // top: -1px;
                    width: $ancho;
                }
            }

            .product-info {
                text-align: center;

                .product-info-table {
                    border-bottom: 1px solid $mercury;
                    display: inline-block;
                    padding: 10px 0 5px;
                    width: 80%;

                    table {
                        width: 100%;

                        thead {
                            tr {
                                th {
                                    color: $gray;
                                    font-size: 12px;
                                    font-weight: 200;

                                    &:not(:last-child) {
                                        border-right: 1px solid $smoke;
                                    }
                                }
                            }
                        }

                        tbody {
                            tr {
                                td {
                                    color: $thunder;
                                    font-size: 12px;
                                    font-weight: 300;
                                }
                            }
                        }
                    }
                }

                .product-info-details {
                    .thickness {
                        display: inline-block;

                        .icon {
                            border-left: 0.5px solid $onyx;
                            border-right: 0.5px solid $onyx;
                            display: inline-block;
                            height: 10px;
                            line-height: 10px;
                            width: 15px;

                            div {
                                background: $onyx;
                                display: inline-block;
                                vertical-align: middle;
                                width: 100%;
                            }
                        }

                        .text {
                            display: inline-block;
                            font-size: 12px;
                            margin-left: 5px;
                        }
                    }

                    .finish {
                        display: inline-block;
                        margin-left: 8px;

                        .icon {
                            display: inline-block;
                            vertical-align: middle;

                            img {
                                height: 15px;
                            }
                        }

                        .text {
                            display: inline-block;
                            font-size: 12px;
                            margin-left: 5px;

                            max-width: 100%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            vertical-align: middle;
                            white-space: nowrap;
                        }
                    }
                }
            }

            .product-actions {
                display: flex;
                margin-top: 10px;

                .input-row {
                    flex-grow: 1;
                    .icon {
                        display: inline-block;

                        img {
                            height: 15px;
                        }
                    }
                }

                .bt-row {
                    flex-grow: 1;
                    max-width: 50%;
                    padding-top: 2px;
                }
            }
        }

        .list-result {
            /*LIST*/
            $ancho: 60px;
            $alto: 60px;
            width: 100%;
            justify-content: space-between;
            display: flex;
            flex-direction: row;
            margin: 12px;
            position: relative;
            text-align: center;
            text-decoration: none;
            vertical-align: top;
            align-items: center;

            .badge {
                background: $onyx;
                position: absolute;
                right: -6px;
                top: -6px;
                z-index: 99;
            }
            .product-image-container {
                align-items: flex-end;
                // background-color: $smoke;
                // background-image: url('../img/icons/no-image.svg');
                // background-position: center;
                // background-repeat: no-repeat;
                // border: 1px dashed $iron;
                display: flex;
                height: $alto;

                position: relative;

                width: $ancho;
                .product-image {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: $alto;
                    // left: -1px;
                    position: absolute;
                    // top: -1px;
                    width: $ancho;
                }
            }
            .product-brand {
                color: #808080;
                font-size: 11px;
                font-weight: 200;
                text-transform: capitalize;
            }
            .product-info {
                text-align: center;
            }
        }
        /*SAMPLE*/
        .sk-hits {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    .results-pagination {
        .sk-pagination-navigation {
            .sk-toggle {
                text-align: center;

                .sk-toggle-option {
                    background: $gray;
                    border: 1px solid $gray;
                    color: #fff;

                    cursor: pointer;
                    display: inline-block;
                    margin-bottom: 10px;
                    padding: 2px 10px;

                    &.is-disabled {
                        background: $mercury;
                        color: $iron;
                        display: none;
                    }

                    &:not(.is-disabled) {
                        &.is-active {
                            background: $thunder;
                            border-color: $thunder;
                        }

                        &:hover {
                            background: $thunder;
                            border-color: $thunder;
                        }
                    }

                    &:not(:first-child) {
                        border-left: 1px solid $white;
                    }

                    &:not(:last-child) {
                        border-right: none;
                    }

                    &[data-key^='ellipsis'] {
                        display: inline-block;
                    }

                    &[data-key='previous'],
                    &[data-key='next'] {
                    }
                }
            }
        }
    }

    .results-page-size-selector {
        text-align: center;

        .sk-select {
            display: inline-block;
            margin-top: 10px;
            text-align: center;
        }
    }
    &--lastProductsBought {
        width: 100%;
        .results-list {
            padding: 0;
            h2 {
                font-family: $titleFont;
                padding: 20px;
            }
            .result {
                width: 240px;
                .product-image-container {
                    height: 240px;
                    width: 240px;
                    .product-image {
                        background-image: url('https://assetstools.cosentino.com/api/v1/bynder/color/EM/detalle/EM-thumb.jpg?w=240&h=240&fit=crop&auto=compress,format');
                        height: 240px;
                        width: 240px;
                    }
                }
            }
        }
    }

    .results__header__options {
        display: flex;
        align-items: flex-end;
        gap: 8px;
        padding-bottom: 24px;

        .pn__switch__tabs {
            display: flex;
            gap: 4px;
            padding: 4px;
            background-color: $white;
            border: 1px solid $primaryColor;

            button {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                border: 0;
                padding: 10px 16px;
                background-color: $white;
                outline: 0;

                svg {
                    margin-right: 8px;

                    rect {
                        fill: $primaryColor;
                    }
                }

                &:hover {
                    background-color: #DBE4E9;
                }

                @media (max-width: $xsMaxWidth) {
                    padding: 10px 6px;
                    font-size: 12px;
                    text-align: center;
                    width: 100%;

                }
            }
            
            &--current-center.active-current-center {
                background-color: $primaryColor;
                color: $white;

                svg {
                    rect {
                        fill: $white;
                    }
                }

                &:hover {
                    background-color: $primaryColor;
                    color: $white;
                }
            }
    
            &--pn.active-pn {
                background-color: $primaryColor;
                color: $white;

                svg {
                    path {
                        fill: $white;
                    }
                }

                &:hover {
                    background-color: $primaryColor;
                    color: $white;
                }
            }

            @media (max-width: $xsMaxWidth) {
                width: 100%;
            }

            @media (max-width: $mdMaxWidth) {
                margin-bottom: 24px;
            }
        }

        .options__filters {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
            gap: 6px;

            @media(max-width: $smMaxWidth) {
                justify-content: flex-start;
            }

            &--count {
                span {
                    color: #8E8E8E;
                    font-weight: 300;
                    line-height: 20.8px;
                    font-size: 16px;
                    text-transform: lowercase;
                }

                .showing {
                    text-transform: capitalize;
                }

                .count-text {
                    color: $primaryColor;
                    font-weight: 400;
                }
            }

            &--icon {
                border: 0;
                background-color: transparent;
                outline: 0;
                padding: 0;

                &:hover {
                    background-color: $primaryColorHover;
                }

            }

            .select-excel-v2 {
                position: relative;
                display: flex;
                flex-direction: column;
                font-weight: bold;
            }

            .custom-options-excel-v2 {
                position: absolute;
                display: block;
                top: 98%;
                left: 0;
                background: $white;
                z-index: 2;
                opacity: 0;
                visibility: hidden;
                text-align: left;
                width: 10rem;
                padding: 8px;
                border: 1px solid gray;

                button {
                    background-color: transparent;
                    border: 0;
                    color: $primaryColor;
                    font-weight: 400;
                    width: 100%;
                    text-align: left;
                    padding: 0;
                    text-transform: capitalize;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .select-excel-v2.open-excel-v2 .custom-options-excel-v2 {
                opacity: 1;
                visibility: visible;
                pointer-events: all;
            }

            .catalog__sorting__selector {
                display: flex;
                gap: 6px;

                .order-by {
                    span {
                        text-transform: lowercase;
                        color: #8E8E8E;
                        font-weight: 300;
                        line-height: 20.8px;
                        font-size: 16px;
                    }
                }

                .order-input {
                    .sk-select {
                        select {
                            cursor: pointer;
                            background-color: transparent;
                            border: 0;
                            color: $primaryColor;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 20.8px;
                            outline: 0;
                        }
                    }
                }
            }
        }

        @media (max-width: $xsMaxWidth) {
            padding-left: 16px;
            padding-right: 16px;
        }

        @media (max-width: $mdMaxWidth) {
            flex-direction: column;
            align-items: baseline;
            gap: 0px;
        }
    }

    .banner__pn__outlet {
        display: flex;
        margin-bottom: 24px;
        background-color: #D7EAF8;
        padding: 8px;
        gap: 8px;

        &--content {
            display: flex;
            align-items: baseline;
            flex-direction: column;
            gap: 6px;

            span {
                color: $primaryColor;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
            }

            .main-text {
                font-weight: 500;
            }

            .content-row {
                display: flex;
                gap: 10px;
                align-items: center;
            }
        }
    }
}
