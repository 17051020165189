
.matNoStock-modal {
    .img {
        margin-right: 10px;
        position: relative;
    }
}
.line-item {
    display: flex;

    .config-bath-details-block {
        display: none;
    }

    @media (max-width: $smMaxWidth) {
        .config-bath-details-block {
            display: block;
        }
    }

    .img {
        margin-right: 16px;
        position: relative;
    }

    .justified {
        align-items: center;
        display: flex;
        justify-content: space-around;
    }
    .title {
        font-size: 14px;
        font-weight: 600;
        flex-grow: 1;
    }
    .subtitle {
        color: $primaryColor;
        font-size: 11px;
        font-weight: 500;
    }

    .content {
        flex-grow: 1;

        .props {
            display: flex;
            justify-content: space-between;
        }
        .description-and-input {
            display: flex;
            flex-direction: column;
            height: 100%;
            max-width: 100%;
            .mini_cart_line_title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;
                .title {
                    flex-grow: 1;
                }
                .lot_id {
                    color: $primaryColor;
                    font-size: 14px;
                    font-weight: 400;
                    font-family: $infoFont;
                    flex-grow: 1;
                }
            }
            .mini_cart_line_block {
                display: flex;
                justify-content: space-between;
                flex-grow: 1;
                .mini_cart_line_price {
                    text-align: right;
                    min-width: 140px;
                    font-size: 11px;
                    .mini_cart_line_info {
                        //input number
                        justify-content: flex-end;
                    }
                }
                .mini_cart_line_info {
                    align-content: flex-start;
                    font-size: 12px;
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    align-items: center;

                    &--vertical_center {
                        display: flex;
                        align-items: center;
                    }
                    &__line {
                        display: flex;
                        margin-right: 10px;
                        white-space: normal;

                        &--dimensions {
                            span {
                                margin-right: 10px;
                                i {
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                    &:last-child {
                        .mini_cart_line_info__line {
                            justify-content: flex-end;
                        }
                    }
                }
            }
        }
    }
    .delete-button {
        > div {
            margin: 0 5px;
            align-items: center;
        }
        align-items: flex-start;
        display: flex;
        justify-content: space-around;
    }
    .img {
        align-items: center;
        display: flex;
        justify-content: flex-start !important;
        flex-direction: column;
        min-width: 120px;
        max-height: 130px;
        img {
            max-height: 65px;
            max-width: 65px;
        }
    }
    span,
    div {
        &::first-letter {
            text-transform: capitalize;
        }
        &.centimeters {
            text-transform: initial;
        }
    }
    &.checkout {
        padding-bottom: 16px;
        .content {
            .description-and-input {
                .mini_cart_line_title {
                    .title {
                        font-size: 14px;
                        font-weight: 500;
                        font-family: $titleFont;
                        color: $primaryColor;

                        .mini_cart_line_title--brand {
                            font-family: $infoFont;
                            font-weight: 300;
                            font-size: 12px;
                        }
                    }
                }

                .mini_cart_line_block {
                    .mini_cart_line_info__line {
                        span {
                            font-size: 12px;
                            font-family: $infoFont;
                            font-weight: 400;
                        }
                    }
                }

            }
        }


        .delete-button,
        .img,
        .mini_cart_line_info.mini_cart_line_info--vertical_center {
            align-items: start !important;
            justify-content: flex-end;
        }

        img {
            max-height: 80px;
            max-width: 80px;
        }
    }

    .product-image-container {
        .product-image {
            background-position: center center !important;
            padding: 4px;
            background-size: cover;
        }

        background-repeat: no-repeat;
    }
}

.line-configurable-img {
    margin-left: auto;
    margin-right: auto;
    max-width: 120px !important;
    max-height: 120px !important;

    @media (max-width: $smMaxWidth) {
        max-width: 85px !important;
        max-height: 85px !important;
    }
}

.cart_line {
    &__link_image {
        width: 100%;
        height: 100%;
    }
}
.catalog-modal-price {
    .info {
        overflow: hidden;
        padding: 0.4rem;
        transition: 0.5s;
        background: #e8edef;
        color: #07887d;
        width: 100%;
        border-radius: 4px;
        &--hidden {
            max-height: 0px;
            padding: 0 10px;
        }
        & > span {
            margin-right: 20px;
        }
        &--promo {
            font-size: 0.8em;
            font-weight: 400;
            line-height: 0.8em;

            ul {
                padding: 0;
                list-style: none;
                text-align: left;
                li {
                    margin: 0 15px;
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px dashed #4d4d4d;
                }
            }
        }
    }
}
.segmentation,
.comment {
    &__button_info {
        cursor: pointer;
        text-align: left;
    }
    .info {
        margin-bottom: 10px;
        max-height: 200px;
        overflow: hidden;
        padding: 10px;
        transition: 0.5s;
        background: #e8edef;
        color: #07887d;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        border-radius: 4px;
        &--hidden {
            max-height: 0px;
            padding: 0 10px;
        }
        & > span {
            margin-right: 20px;
        }
    }
}

.prefered-date-picker {
    cursor: pointer;
    color: $brandNewBlue;
    font-family: $infoFont;
    font-size: 12px;
    font-weight: 500;

    &:hover {
        text-decoration: underline;
    }
}

.prefered-date-warning-icon {
    color: #FF6805;
    font-size: 18px;
}

.prefered-date-advertisement {
    display: flex;
    column-gap: 8px;
    background-color: $warning;
    border-radius: 2px;
    padding: 4px 8px;
    font-size: 12px;
    color: $primaryColor;
    font-weight: 400;
    font-family: $infoFont;
    margin-bottom: 8px;
}


.delivery__date__tooltip {
    background-color: $bluePrimary !important;
    color: $white !important;
    font-size: 12px !important;
    font-family: $infoFont !important;
    font-weight: 400 !important;
    max-width:240px !important;
    opacity: 1 !important;

    &:after{
        border-bottom-color: $bluePrimary !important;
        border-top-color: $bluePrimary !important;
    }
}

.cartline-custom-alert-msg {
    border-radius: 0;
    background-color: #FFF6D4;
    border-color: #FFF6D4;
    color: #1F2228;
    font-weight: 500;
    font-size: 12px;
    font-family: $infoFont;
    max-width: 365px;

    i {
        color: #CC9F00;
    }
}

// NEW CARTLINE STYLES (10/01/2025)

.line__item__checkout {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #EBEBEB;

    .line__item__checkout--delivery-date {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        background-color: $backgroundColor;
        padding: 8px;
        font-family: $infoFont;
        margin-bottom: 8px;

        @media (max-width: $smMaxWidth) {
            justify-content: flex-start;
            flex-direction: column;
            align-items: baseline;
        }

        .delivery-date-info {
            padding-right: 4px;
            
            span {
                color: $primaryColor;
                font-size: 12px;
                line-height: 16px;
                font-weight: 300;
            }

            .delivery-date-text {
                font-weight: 400;
            }
        }

        .delivery-date-selector {
            font-size: 12px;
        }
    }

    .line__item__checkout--content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: $smMaxWidth) {
            flex-direction: column;
            align-items: baseline;
        }

        .content--left {
            display: flex;
            gap: 12px;

            .image-block {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                width: 115px;
                height: 110px;
                min-width: 115px;
                min-height: 110px;

                @media (max-width: $smMaxWidth) {
                    min-width: 115px;
                    min-height: 97px;
                }

                .product-image-container {

                    .product-image {
                        background-position: center center !important;
                        padding: 2px;
                    }

                    .product-image:not([type='TABLA']) {
                        background-size: contain;
                    }

                    .product-image {
                        background-size: cover;
                       
                    }
                }
            }

            .text-block {
                .product-brand {
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 16px;
                    color: $primaryColor;
                }

                .product-title-block {
                    .product-title {
                        margin: 0;
                        color: $primaryColor;
                        font-size: 16px;
                        line-height: 20px;

                        @media (max-width: $smMaxWidth) {
                            display: block;
                        }
                    }

                    
                    .feature {
                        color: $primaryColor;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 20px;
                        font-family: $titleFontRegular;
                        text-align: left;
                        margin-right: 4px;                   
                    }

                    .feature-in-step-two {
                        font-size: 12px;
                    }
                }



                .product-id {
                    line-height: 16px;
                    padding: 6px 0;
                    span {
                        color: $primaryColor;
                        font-weight: 400;
                        font-size: 12px;
                    }
                }

                .product-id-in-step-two {
                    padding-top: 0;
                }

                .text-feature, span {
                    color: $primaryColor;
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 18px;
        
                    i {
                        color: $primaryColor;
                        padding-right: 4px;
                    }

                    .bundle-btn {
                        cursor: pointer;
                        
                        span {
                            text-decoration: underline;

                            &:hover {
                                color: #000;
                            }
                        }                       
                    }
                }

                .indicators-block {
                    flex-wrap: wrap;
                    display: flex;
                    align-items: baseline;
                    gap: 4px;

                    .discontinued-indicator {
                        display: inline;
                    }

                    .outlet-indicator {
                        display: inline;
                    }
                }
            }
        }
        
        .segmentation-box {
            line-height: 16px;

            .segmentation-title {
                font-size: 12px;
                font-weight: 300;
                line-height: 16px;

                &--title {
                    font-weight: 400;
                }
            }

            .segmentation-link {
                font-size: 12px;
                font-weight: 300;
                line-height: 16px;
                text-decoration: underline;
                cursor:pointer;
                color: #5C5C5C;

                &:hover {
                    color: $primaryColor;
                }
            }
        }

        .comment-box {
            line-height: 16px;

            .comment-title {
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
            }

            .comment-content {
                font-size: 12px;
                font-weight: 300;
                line-height: 16px;
            }

            .comment-link {
                font-size: 12px;
                font-weight: 300;
                line-height: 16px;
                text-decoration: underline;
                cursor:pointer;
                color: #5C5C5C;

                &:hover {
                    color: $primaryColor;
                }
            }
        }

        .segmentation-comment-desktop {
            display: block;

            @media (max-width: $smMaxWidth) {
                display: none;
            }
        }

        .segmentation-comment-responsive {
            display: none;
            @media (max-width: $smMaxWidth) {
                display: block;
            }
        }

        .alert-box-desktop {
            display: block;

            @media (max-width: $smMaxWidth) {
                display: none;
            }
        }

        .alert-box-responsive {
            display: none;

            @media (max-width: $smMaxWidth) {
                display: block;
            }
        }

        .line-actions-desktop {
            display: block;

            @media (max-width: $smMaxWidth) {
                display: none;
            }
        }

        .line-actions {
            display: flex;
            align-items: center;
            gap: 16px;
            padding-top: 16px;

            &--plus-less {
                font-size: 12px;
                color: $primaryColor;
                font-weight: 300;
                border-right: 1px solid #A9AEBC;
                padding-right: 16px;

                @media (max-width: $smMaxWidth) {
                    width: 100%;
                    border: 0;
                    padding: 0;
                }

                .input-number {
                    display: flex;
                    gap: 2px;

                    @media (max-width: $smMaxWidth) {
                        width: 100%;
                        max-width: 100%;
                    }
                    
                    .spin {
                        width: 31px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @media (max-width: $smMaxWidth) {
                            width: 100%;
                            max-width: 100%;
                        }
                    }

                    .input-container {
                        width: 31px;
                        height: 30px;

                        @media (max-width: $smMaxWidth) {
                            width: 100%;
                            max-width: 100%;
                        }

                        .inner-input-number {
                            background-color: $white;
                            border: 1px solid $primaryColor;
                            width: 31px;
                            height: 30px;

                            @media (max-width: $smMaxWidth) {
                                width: 100%;
                                max-width: 100%;
                            }
                        }
                    }

                }
            }

            &--delete {
                min-height: 30px;

                button {
                    background-color: transparent;
                    border: 0;
                    font-size: 12px;
                    color: $primaryColor;
                    font-weight: 300;
                    padding: 0;

                    &:hover {
                        text-decoration: underline;
                    }

                    i {
                        padding-right: 4px;
                    }
                }
            }

            &--fav {
                min-height: 30px;
                padding-left: 16px;
                border-left: 1px solid #A9AEBC;

                button {
                    background-color: transparent;
                    border: 0;
                    font-size: 12px;
                    color: $primaryColor;
                    font-weight: 300;
                    padding: 0;

                    &:hover {
                        text-decoration: underline;
                    }

                    i {
                        padding-right: 4px;
                    }
                }
            }
        }

        .increment-decrement-responsive {
            display: none;
            @media (max-width: $smMaxWidth) {
                display: block;
            }
        }

        .delete-and-favourite-blocks-responsive {
            display: none;
            @media (max-width: $smMaxWidth) {
                padding-top: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .line-actions--fav {
                border: 0;
            }
        }
    }

    .content--right {
        min-width: 150px;

        @media (max-width: $smMaxWidth) {
            flex-direction: column;
            width: 100%;
            border-top: 1px solid #EBEBEB;
        }

        .line-price-info {
            display: flex;
            align-items: flex-end;
            flex-direction: column;
            gap: 6px;
            text-align: right;

            @media (max-width: $smMaxWidth) {
                align-items: center;
                flex-direction: row;
                text-align: center;
                justify-content: center;
                gap: 16px;
                padding: 16px 0;
            }


            .line-price {
                font-weight: 500;
                font-size: 22px;
                line-height: 23px;
                color: $primaryColor;
            }

            .line-price-sub-info {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: $primaryColor;
            }
        }
    }

    .line-price-info-in-step-two {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: $primaryColor;
        padding: 6px 0 0 0 ;

        .line-price {
            span {
                font-size: 12px !important;
                font-weight: 500 !important;
            }
        }
    }

    .estimated_date_block {
        padding-top: 6px;
        font-family: $infoFont;
        font-size: 12px;
        font-weight: 400;
        color: $primaryColor;
    }

}