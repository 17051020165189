#section-catalog {
    .search-container {
        background-image: url('../img/backgrounds/dekton-kitchen-nilium.jpg');
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-size: cover;
        background-position: center;

        .search-overlay {
            background: rgba(0, 0, 0, 0.4);

            .search-box-container {
                padding: 60px 0;

                .search-box {
                    position: relative;
                    padding: 0;
                    border: none;
                    .sk-search-box {
                        background: $white;
                        border-radius: 15px;

                        .sk-search-box__icon {
                            position: relative;
                            width: 100%;

                            &:before {
                                content: '\f002';
                                font-family: 'Font Awesome 5 Pro';
                                left: 12px;
                                position: absolute;
                                top: 4px;
                                [dir='rtl'] & {
                                    left: 12px;
                                    right: unset;
                                }
                            }
                        }

                        .sk-search-box__text {
                            width: 100%;
                            border: none;
                            border-radius: 15px;
                            padding: 7px 15px 5px 40px;
                            outline: none;
                            [dir='rtl'] & {
                                padding: 7px 40px 5px 15px;
                            }
                        }

                        .sk-search-box__action {
                            display: none;
                        }
                    }

                    .bt-reset-searchbox {
                        position: absolute;
                        top: 13px;
                        right: 10px;
                        width: 21px;
                        height: 21px;

                        background: $gray;
                        border-radius: 10px;

                        cursor: pointer;

                        &:before {
                            position: absolute;
                            top: 1px;
                            width: 100%;

                            font-family: 'Font Awesome 5 Pro';
                            font-size: 14px;
                            content: '\f00d';
                            text-align: center;
                            color: $white;
                        }
                        [dir='rtl'] & {
                            right: unset;
                            left: 10px;
                        }
                    }
                }
            }
        }
    }
    &.no-padding {
        .filter-and-results-container {
            padding: 0;
            .results .results-list {
                padding: 0;
            }
        }
    }
    .filter-and-results-container {
        @media (max-width: 992px) {
            padding: 40px 0;
        }
        .outlet-title {
            font-family: $titleFont;
        }
        .toggle-mobile-filters {
            position: relative;
            border: 1px solid $onyx;
            margin-bottom: 20px;
            padding: 10px;
            border-radius: 30px;
            text-align: center;

            cursor: pointer;

            img {
                position: absolute;
                top: 50%;
                left: 15px;
                height: 20px;
                margin-top: -10px;
            }
        }
        .clean-filters {
            font-weight: bold;
            cursor: pointer;
        }
        .clean-filters:hover{
            text-decoration: underline;
        }
        .tags_clean {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            gap: 16px;

            .selected-filter--type,
            .selected-filter--subtype{
                display: none !important;
            }

            .sk-selected-filters, .clean-filters {
                padding-top: 10px;
            }

            @media (max-width: $smMaxWidth) {
                flex-direction: column;
            }
        }

        .filters {
            flex-direction: column;
            @media (max-width: 991px) {
                display: flex;
            }
            @media (max-width: 776px) {
                display: none;
            }
            @media (max-width: $mdMaxWidth) {
                display: none;
                position: fixed;
                background: $white;
                top: 0;
                left: 0;
                bottom: 0;
                width: 100%;
                overflow-x: none;
                overflow-y: scroll;
                z-index: $layer20;
                padding: 10px 10px 70px;
                -webkit-overflow-scrolling: touch;
            }
                        
            .row {
                margin-bottom: 20px;
            }

            /* .sk-panel.is-disabled {
                visibility: hidden;
            } */
            .filter--favourite {
                .sk-item-list-option__text {
                    &:before {
                        content: '\f004';
                        font-family: 'Font Awesome 5 Pro';
                        left: -5px;
                        position: relative;
                        [dir='rtl'] & {
                            left: 5px;
                        }
                    }
                }
            }
            .filter--discounts {
                .sk-item-list-option__text {
                    &:before {
                        content: '\f02c';
                        font-family: 'Font Awesome 5 Pro';
                        left: -5px;
                        position: relative;
                        [dir='rtl'] & {
                            left: 5px;
                        }
                    }
                }
            }
            .filter--favourite,
            .filter--discounts {
                .sk-panel__header {
                    font-family: $titleFont;
                    font-weight: 300;
                    color: $thunder;
                }

                .sk-panel__content {
                    .active {
                        .sk-item-list__item {
                            padding: 3px 5px;
                            border: 2px solid $thunder;

                            .sk-item-list-option__text {
                                &:before {
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                    .sk-item-list__item {
                        position: relative;
                        margin-top: 3px;
                        padding: 4px 6px;
                        cursor: pointer;

                        border: 1px solid $mercury;
                        border-radius: 10px;

                        text-align: center;

                        &:hover,
                        &.is-active {
                            background-color: $primaryColorHover;
                        }

                        &.is-active {
                            padding: 3px 5px;
                            border: 2px solid $thunder;

                            .sk-item-list-option__text {
                                &:before {
                                    font-weight: bold;
                                }
                            }
                        }

                        .sk-item-list-option__checkbox {
                            display: none;
                        }

                        .sk-item-list-option__text {
                            display: inline-block;
                            vertical-align: middle;
                            font-weight: 400;
                            font-size: 13px;
                        }

                        .sk-item-list-option__count {
                            display: none;
                        }
                    }
                }
            }
            .filters__contain.mktools {
                .Select-option {
                    font-size: 12px;
                }
            }
            .mktools .filter--subtype {
                width: 100%;
                .Select-value {
                    text-transform: lowercase;
                    &::first-letter {
                        text-transform: uppercase;
                    }
                }
                .Select-option {
                    text-transform: lowercase;
                    &::first-letter {
                        text-transform: uppercase;
                    }
                }
            }
            &__contain {
                //display: flex;
                //flex-wrap: wrap;

                /* & > * {
                    width: 50%;
                    margin-bottom: 20px;
                } */
                .filter--subtype1,
                .filter--subtype2,
                .filter--mktoolShippingCenter,
                .filter--color {
                    width: 100%;
                    .Select-value {
                        text-transform: lowercase;
                        &::first-letter {
                            text-transform: uppercase;
                        }
                    }
                    .Select-option {
                        text-transform: lowercase;
                        &::first-letter {
                            text-transform: uppercase;
                        }
                    }
                }
                .filter--bigType,
                .filter--brands,
                .filter--brand,
                .filter--finish,
                .filter--subtype,
                .filter--subtype1,
                .filter--subtype2,
                .filter--subtype3,
                .filter--mktoolShippingCenter,
                .filter--color,
                .filter--technology,
                .filter--finishFamily,
                .filter--finish2,
                .filter--channelName,
                .filter--webHeadingDesc,
                .filter--outletQuality,
                .filter--discontinued,
                .filter--outletDeviated,
                .filter--operationType,
                .filter--favouriteV2,
                .filter--discountsV2,
                .filter--typeId,
                .filter--colorSerieDesc,
                .filter--tone,
                .filter--zaddon {
                    .sk-panel__header {
                        font-family: $titleFont;
                        font-weight: 300;
                        color: $thunder;
                    }

                    .sk-panel__content {
                        .sk-item-list__item {
                            cursor: pointer;
                            &:hover {
                                background-color: $mercury;
                            }
                            @media (max-width: $smMaxWidth) {
                                padding: 8px 12px;
                            }

                            .sk-item-list-option__checkbox::-ms-check {
                                visibility: hidden;
                            }
                            .sk-item-list-option__checkbox {
                                display: inline-block;
                                margin-right: 7px;
                                vertical-align: middle;
                                appearance: none;
                                -webkit-appearance: none;
                                padding: 8px;
                                [dir='rtl'] & {
                                    margin-right: unset;
                                    margin-left: 7px;
                                }
                            }
                            &.is-active {
                                .sk-item-list-option__text {
                                    font-weight: 300;
                                }
                            }
                            .sk-item-list-option__checkbox::-ms-check {
                                visibility: hidden;
                            }
                            /* .sk-item-list-option__checkbox {
                                display: inline-block;
                                margin-right: 7px;
                                vertical-align: middle;
                                appearance: none;
                                -webkit-appearance: none;
                                padding: 8px;

                                border: none;
                                border-radius: 0;
                                background-color: transparent;
                                background-image: url('../img/components/checkbox-unchecked.svg');
                                background-repeat: no-repeat;
                                background-size: contain;

                                opacity: 0.7;

                                cursor: pointer;

                                &:checked {
                                    background-image: url('../img/components/checkbox-checked.svg');
                                }
                            } */

                            .sk-item-list-option__checkbox {
                                display: inline-block;
                                margin-right: 8px;
                                vertical-align: middle;
                                appearance: none;
                                -webkit-appearance: none;
                                padding: 8px;

                                border: none;
                                border-radius: 0;
                                background-color: transparent;
                                background-image: url('../img/components/checkbox-off.svg');
                                background-repeat: no-repeat;
                                background-size: contain;

                                opacity: 0.9;

                                cursor: pointer;

                                &:checked {
                                    background-image: url('../img/components/checkbox-on.svg');
                                }
                            }

                            .sk-item-list-option__text {
                                display: inline-block;
                                vertical-align: middle;
                                text-transform: lowercase;
                                font-weight: 200;
                                font-size: 14px;
                                width: 70%;

                                &::first-letter {
                                    text-transform: uppercase;
                                }
                            }

                            .sk-item-list-option__count {
                                display: none;
                            }
                        }
                    }
                }
            }

            .filter--format {
                .sk-panel__header {
                    font-family: $titleFont;
                    font-weight: 300;
                    color: $thunder;
                }

                .sk-panel__content {
                    .sk-item-list__item {

                        &.is-active {
                            background-color: $mercury;

                            .sk-item-list-option__text {
                                font-weight: 300;
                            }
                        }

                        &:hover {
                            background-color: $mercury;
                        }

                        .sk-item-list-option__checkbox {
                            display: none;
                        }

                        .sk-item-list-option__text {
                            display: inline-block;
                            vertical-align: middle;
                            text-transform: lowercase;
                            font-weight: 200;
                            font-size: 13px;

                            &::first-letter {
                                text-transform: uppercase;
                            }
                        }

                        .sk-item-list-option__count {
                            display: none;
                        }
                    }
                }

                /* [data-qa='show-more'] {
                    color: $gray;
                    font-size: 12px;
                    margin-top: 5px;
                    cursor: pointer;

                    &:hover {
                        text-decoration: underline;
                    }
                } */
            }

            .filter--thickness {
                .sk-panel__header {
                    font-family: $titleFont;
                    font-weight: 300;
                    color: $thunder;
                }

                .sk-panel__content {
                    .sk-item-list__item {
                        position: relative;
                        cursor: pointer;

                        @media (max-width: $smMaxWidth) {
                            padding: 8px 12px;
                        }

                        &[data-key='0.4'] .sk-item-list-option__count {
                            border-bottom-width: 0.4px;
                            margin-top: -0.2px;
                        }

                        &[data-key='0.8'] .sk-item-list-option__count {
                            border-bottom-width: 2.4px;
                            margin-top: -0.4px;
                        }

                        &[data-key='1.2'] .sk-item-list-option__count {
                            border-bottom-width: 3.2px;
                            margin-top: -0.6px;
                        }

                        &[data-key='1.4'] .sk-item-list-option__count {
                            border-bottom-width: 3.4px;
                            margin-top: -0.7px;
                        }

                        &[data-key='1.6'] .sk-item-list-option__count {
                            border-bottom-width: 3.6px;
                            margin-top: -0.8px;
                        }

                        &[data-key='2'] .sk-item-list-option__count {
                            border-bottom-width: 4px;
                            margin-top: -1px;
                        }
                        &[data-key='2.0'] .sk-item-list-option__count {
                            border-bottom-width: 4px;
                            margin-top: -1px;
                        }

                        &[data-key='3.0'] .sk-item-list-option__count {
                            border-bottom-width: 5px;
                            margin-top: -1.5px;
                        }

                        &.is-active {
                            background-color: $mercury;

                            .sk-item-list-option__text {
                                font-weight: 300;
                            }
                        }

                        &:hover {
                            background-color: $mercury;
                        }

                        .sk-item-list-option__checkbox {
                            display: none;
                        }

                        .sk-item-list-option__text {
                            display: inline-block;
                            vertical-align: middle;
                            text-transform: lowercase;
                            font-weight: 200;
                            font-size: 13px;

                            &::first-letter {
                                text-transform: uppercase;
                            }
                        }

                        .sk-item-list-option__count {
                            position: absolute;
                            right: 0;
                            top: 50%;
                            width: 50%;
                            height: 0;
                            margin-right: 2px;
                            color: transparent;
                            border-bottom-color: $thunder;
                            border-bottom-style: solid;
                            border-bottom-width: 0;
                            [dir='rtl'] & {
                                right: unset;
                                left: 0;
                            }
                        }
                    }
                }
            }
            .filter--webHeading,
            .filter--type,
            .with_icon .filter--bigType {
                .sk-panel__header {
                    font-family: $titleFont;
                    font-weight: 300;
                    color: $thunder;
                }

                .sk-panel__content {
                    text-align: left;

                    .sk-item-list__item {
                        display: inline-block;
                        vertical-align: top;
                        position: relative;
                        cursor: pointer;
                        width: 70px;
                        height: 70px;
                        padding: 2px;
                        margin: 10px 7px 35px;

                        border-radius: 50%;
                        border: 1px solid $mercury;

                        background-position: center 5px;
                        background-size: 90% auto;
                        background-repeat: no-repeat;

                        font-family: SilestoneWebSymbols;

                        &::before {
                            color: $thunder;
                            font-size: 65px;
                        }

                        &[data-key='TABLA'],
                        &[data-key='ENCIMERA'] {
                            background-image: url('../img/icons/products/tables.svg');
                        }
                        &[data-key='OPERATIONS'] {
                            background-image: url('../img/icons/products/operaciones.svg');
                            background-position: center;
                            background-size: 90%;
                            background-position-x: 5px;
                        }
                        &[data-key='SERVICE'] {
                            background-image: url('https://www.ikea.com/es/es/images/visual-navigation/ikea-instalacion__1364635603443-s1.png');
                            background-position: center;
                            background-size: 50%;
                            background-position-x: 55%;
                        }

                        &[data-key='LAVABOS'] {
                            background-image: url('../img/icons/products/lavabos.svg');
                            background-position: center;
                            background-size: 90%;
                        }

                        &[data-key='FREGADERO'] {
                            background-image: url('../img/icons/products/fregadero.svg');
                            background-position: center;
                            background-size: 90%;
                        }

                        &[data-key='PLATOSDUCHA'] {
                            background-image: url('../img/icons/products/platoducha.svg');
                            background-position: center;
                            background-size: 90%;
                        }

                        &[data-key='PPCC'] {
                            // content: 'Ë';
                            background-image: url('../img/icons/products/productos-pro.svg');
                            // line-height: 70px;
                        }
                        &[data-key='WH'] {
                            background-image: url('../img/icons/products/warehouse.svg');
                            background-position: center;
                            background-size: 65%;
                        }
                        &[data-key='NEWS'] {
                            background-image: url('../img/icons/products/news.svg');
                            background-position: center;
                            background-size: 65%;
                        }
                        &[data-key='18'] {
                            background-image: url('../img/icons/products/sales.svg');
                            background-position: center;
                            background-size: 100%;
                        }
                        //samples
                        &[data-key='01'] {
                            background-image: url('../img/icons/products/muestra.svg');
                            background-position: center;
                            background-size: 65%;
                        }
                        //others
                        &[data-key='02'] {
                            background-image: url('../img/icons/products/otros.svg');
                            background-position: center;
                            background-size: 65%;
                        }
                        //merchandising
                        &[data-key='03'] {
                            background-image: url('../img/icons/products/merchandising.svg');
                            background-position: center;
                            background-size: 65%;
                        }
                        //literature (antigua papelería)
                        &[data-key='04'] {
                            background-image: url('../img/icons/products/papeleria.svg');
                            background-position: center;
                            background-size: 45%;
                        }
                        &[data-key='05'] {
                            background-image: url('../img/icons/products/tags.svg');
                            background-position: center;
                            background-size: 110%;
                        }
                        &[data-key='06'] {
                            background-image: url('../img/icons/products/anchors.svg');
                            background-position: center;
                            background-size: 110%;
                        }
                        //cajas
                        &[data-key='07'] {
                            background-image: url('../img/icons/products/caja.svg');
                            background-position: center;
                            background-size: 65%;
                        }
                        //displays (antiguo expositores y torres)
                        &[data-key='08'] {
                            background-image: url('../img/icons/products/torre-expositor.svg');
                            background-position: center;
                            background-size: 60%;
                        }
                        //libros
                        &[data-key='09'] {
                            background-image: url('../img/icons/products/libro.svg');
                            background-position: center;
                            background-size: 45%;
                        }
                        &[data-key='10'] {
                            background-image: url('../img/icons/products/gabinets.svg');
                            background-position: center;
                            background-size: 110%;
                        }
                        &[data-key='11'] {
                            background-image: url('../img/icons/products/signage.svg');
                            background-position: center;
                            background-size: 110%;
                        }
                        //tes_kits
                        &[data-key='12'] {
                            background-image: url('../img/icons/products/test-kit.svg');
                            background-position: center;
                            background-size: 60%;
                        }
                        &[data-key='13'] {
                            background-image: url('../img/icons/products/workwear.svg');
                            background-position: center;
                            background-size: 65%;
                        }
                        &[data-key='14'] {
                            background-image: url('../img/icons/products/ppe.svg');
                            background-position: center;
                            background-size: 65%;
                        }
                        &[data-key='15'] {
                            background-image: url('../img/icons/products/supplies.svg');
                            background-position: center;
                            background-size: 65%;
                        }
                        &[data-key='MK'] {
                            background-image: url('../img/icons/products/mktools.svg');
                            background-position: center;
                            background-size: 65%;
                        }

                        &[data-key='SH'] {
                            background-image: url('../img/icons/products/showroom.svg');
                            background-position: center;
                            background-size: 65%;
                        }
                        &[data-key='BAÑOS'] {
                            background-image: url('../img/icons/products/lavabos.svg');
                            background-position: center;
                            background-size: 90%;
                            
                        }
                        &.is-active {
                            background-color: $mercury;
                            border: 2px solid $thunder;

                            .sk-item-list-option__text {
                                font-weight: 300;
                            }
                        }

                        &:hover {
                            background-color: $mercury;
                        }

                        .sk-item-list-option__checkbox {
                            display: none;
                        }

                        .sk-item-list-option__text {
                            position: absolute;
                            display: block;
                            top: 75px;
                            width: 100%;
                            text-align: center;
                            font-family: $infoFont;
                            font-weight: 200;
                            font-size: 12px;
                            line-height: 15px;
                        }

                        .sk-item-list-option__count {
                            display: none;
                        }
                    }
                }
            }

            .mobile-actions {
                position: fixed;
                bottom: 0;
                left: 0;
                width: 100%;

                .bbt {
                    display: inline-block;
                    padding: 20px 10px;
                    width: 50%;
                    text-align: center;
                    color: $white;
                    font-size: 17px;

                    cursor: pointer;

                    &.bt-clear-filters {
                        background: white;
                        color:black;
                    }

                    &.bt-apply-filters {
                        background: $onyx;
                    }
                }
            }

            [data-qa='show-more'] {
                color: $secondNeutralText;
                font-size: 14px;
                cursor: pointer;
                padding: 6px 8px;
                font-weight: 300;

                &:hover {
                    text-decoration: underline;
                }
            }
            /* &.is-disabled {
                display: none;
            } */
        }

        .results {
            h1 {
                display: inline-block;
            }
        }

        .Select {
            border: 1px solid black;
            border-radius: 6px;
        }
    }
    /* .mktoolShippingCenter--always-visible .filter--mktoolShippingCenter.is-disabled {
        display: inline !important;
    } */
}
.wrapper_banner-interior {
    background-color: #e8edef;
    margin-bottom: 1.5rem;
}
.banner-interior {
    margin: 0 auto;
    max-width: 825px;
    text-align: center;
    background-size: contain;
    position: relative;
    background-repeat: no-repeat;
    background-attachment: scroll;
    height: 170px;
    color: white;
    padding-top: 0.5rem;
    background-position: center top;
    background-color: #e8edef;

    .title {
        color: black;
        font-family: questa_bold;
        max-width: 60%;
        font-size: 20px;
        [dir='rtl'] & {
            max-width: 100%;
            padding: 2px 2px 2px 25px;
            text-align: left;
        }
    }
    .virtual_visit_subtitle {
        color: black;
        font-family: questa_bold;
        max-width: 60%;
        font-size: 15px;
        [dir='rtl'] & {
            max-width: 100%;
            padding: 2px 2px 2px 25px;
            text-align: left;
        }
    }
    .slide-bt {
        max-width: 60%;
        .bt {
            padding: 1px 65px;
            font-weight: 300 !important;
            font-size: 18px !important;
            text-transform: none;
            background: #000000;
        }
        [dir='rtl'] & {
            max-width: 100%;
            padding: 2px 2px 2px 25px;
            text-align: left;
        }
    }

    @media (max-width: $mdMaxWidth) and (min-width: $xsMaxWidth) {
        // height: auto;
        text-align: center;
        .title {
            max-width: 70%;
            font-size: 24px;
        }
        .virtual_visit_subtitle {
            max-width: 70%;
            font-size: 16px;
            // color: transparent;
        }
    }

    @media (max-width: $xsMaxWidth) {
        background-image: url('../img/banners-virtual-visit/banner-home-draw.svg') !important;
        background-position: top;
        height: 340px;
        .title {
            max-width: 100%;
            font-size: 22px;
        }
        .virtual_visit_subtitle {
            max-width: 100%;
            font-size: 14px;
            color: transparent;
        }
        .slide-title {
            position: absolute;
            top: 30px;
            left: 30px;
            right: 30px;
        }
        .slide-bt {
            visibility: hidden;
            position: absolute;
        }
    }
}
.similar_colors {
    margin-bottom: 20px;
    text-align: left;

    &__result {
        display: inline-block;
        text-decoration: none;
        vertical-align: top;

        &:hover {
            .product-actions {
                opacity: 1;
            }
        }

        .product-image-container {
            position: relative;
            // background-color: $smoke;
            // background-image: url('../img/icons/no-image.svg');
            // background-repeat: no-repeat;
            // background-position: center;

            width: 140px;
            height: 140px;
            // border: 1px dashed $iron;
            border-radius: 50%;
            margin: 0 auto;

            .product-image {
                position: absolute;
                // top: -1px;
                // left: -1px;
                width: 140px;
                height: 140px;
                border-radius: 50%;
                [dir='rtl'] & {
                    left: 1px;
                }
            }
        }

        .product-info {
            text-align: center;

            .product-brand {
                margin-top: 10px;
                font-weight: 200;
                font-size: 11px;
                color: $gray;
                text-transform: capitalize;
            }

            .product-color-name {
                color: $thunder;
                font-family: $titleFont;
                text-transform: capitalize;
                line-height: 20px;
            }
        }
    }
}

.v2 {
    #section-catalog .filter-and-results-container .filters{
        @media (max-width: $mdMaxWidth) {
            display: unset;
            position: unset;
            background: unset;
            top: unset;
            left: unset;
            bottom: unset;
            width: unset;
            overflow-x: unset;
            overflow-y: unset;
            z-index: unset;
            padding: unset;
            -webkit-overflow-scrolling: unset;
            margin-top: 16px;
        }
    }
    .filter-responsive{
        display: none;
        @media (max-width: $smMaxWidth) {
            display: inline-block;
            position: relative;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .sk-panel__header{
        display: none;
    }

    .sk-selected-filters {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: small;
        gap: 4px;

        .sk-selected-filters__item{
            background-color: #DBE4E9;

            padding: 4px 8px;
            display: flex;
            align-items: center;
            div {
                color: rgb(29, 29, 247);
            }

            .sk-selected-filters-option__remove-action{
                margin-left: 4px;
                visibility: hidden;
            }
            .sk-selected-filters-option__remove-action:after{
                content: "X";
                visibility: visible;
                font-size: smaller;
                font-weight: 400;
            }
            .sk-selected-filters-option__remove-action:hover{
                cursor: pointer;
            }
        }

    }
    .first-line {
        display: flex;
        //flex-wrap: wrap;
        gap: 10px;       
        align-items: baseline;
        flex-direction: column;

        .filter-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 16px;
            width: 100%;

            .filter-items {
                border-right: 1px solid #C7C7C7;
                display: contents;

                @media (max-width: $smMaxWidth) {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    border: 0;
                }

                .filter-item {
                    color: $primaryColor;
                }

                &--child {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    flex-wrap: wrap;
                    justify-content: flex-start;

                    @media (max-width: $smMaxWidth) {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                        align-items: normal;
                        justify-content: normal;
                    }
                }

                /* Hiding the filter-item if .is-disabled is inside */
                .filter-item:has(.is-disabled) {
                    display: none;
                }
            }

            .catalog-toggle-filters {
                display: flex;
                align-items: center;
                min-height: 42px;
                border-left: 1px solid #C7C7C7;

                @media (max-width: 776px) {
                    display: none;
                }

                &--btn {
                    border: 0;
                    color: $primaryColor;
                    background-color: transparent;
                    font-weight: 500;
                    max-height: 32px;
                    margin-left: 6px;
                }


            }
        }

        .hidden-filter {
            display: none;
        }
    }

    .filter-container{
        width: -webkit-fill-available;

        .catalog__group_filters_options {
            padding-top: 24px;
            padding-bottom: 24px;
            background-color: transparent;

            @media (max-width: $smMaxWidth) {
                padding: 0 16px 16px 16px;
            }
        }
    }

    .filtersV2-box:has(.is-disabled) {
        display: none;

        //opacity: 0.7;
        //pointer-events: none; /* Desactiva interacciones */
    }

    .filtersV2-box {
        width: fit-content !important;      
        border: 1px solid $primaryColor;
        padding-right: 12px;
        padding-top: 8px;
        padding-bottom: 8px;
        background-color: $white;

        @media (max-width: $smMaxWidth) {
            width: 100% !important;
            padding: 0;
        }

        img {
            float: right;

            &:hover {
                cursor: pointer;
            }
        }

        .Select {
            border: none !important;
            border-radius: 0 !important;
        }

        .filter-container {
            background-color: $white;

            &.filter-active {
                position: relative;
                z-index: 1000;
            }

            .filters-box-items {
                display: inline-block;
                margin-left: 12px;
                cursor: pointer;

                @media (max-width: $smMaxWidth) {
                    display: block;
                    margin-left: 0;
                    padding: 8px 12px;
                }
            }

            .filter-list {
                min-width: 12rem;
                position: absolute;
                background-color: $white;
                left: -1px;
                border: 1px solid $primaryColor;
                margin-top: 8px;
                color: $primaryColor;

                .sk-item-list {
                    .sk-item-list__item {
                        padding: 6px 8px;
                        border-bottom: 1px solid $primaryColor;

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }

                @media (max-width: $smMaxWidth) {
                    left: -1px;
                    right: -1px;
                    min-width: 100%;
                    margin-top: 0;
                    padding: 0;
                }
            }
        }
    }

   .contain-favourites {
        margin-left: auto;

       &:nth-child(11){
           width: 100%;
       }

       @media (max-width: 1398px){
           width: 100%;
       }
        @media (max-width: 1200px){
            margin-left: 0;
        }

        .sk-item-list-option__text {
            min-width: 180px;
            font-family: $infoFont;
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 12px;
            color: $primaryColor;
        }

        .filter--favouriteV2 {
            .sk-item-list__item {
                @media (max-width: $smMaxWidth) {
                    padding-left: 0 !important;
                }
            }
        }
   }

    #section-catalog {
        
        .results .results-list{
            padding:0;

            @media (max-width: 576px) {
                padding: 0 1rem;
            }
        }
        .results h1 {
            font-family: $titleFont;
        }
        .search-container {
            all: unset;
            // backgground:initial
            .search-overlay {
                all: unset;
                .search-box-container {
                    margin: 24px 0px 0px 0px;
                    padding: 8px 0px;
                    
                    @media (max-width: $smMaxWidth) {
                        padding: 16px;                        
                    }

                    .sk-search-box__icon{
                        top:8px;
                    }
                    .sk-search-box__text{
                        height:48px !important;
                        border-radius: 4px !important;
                        padding:8px 16px 8px 40px !important;
                    }
                }
            }
        }
        .filter-and-results-container {
            padding:0px;
            .sk-hits{
                display:grid;
                grid-template-columns: repeat(4, calc(25% - 63px/4));
                grid-gap: 24px 21px;

                .rectangle-result {
                        margin: 0;
                        width: 100%;
                
                        .product-image-container,
                        .product-image {
                            width: 100%;
                
                        }
                    }
                @media (max-width: $lgMaxWidth) {
                    grid-template-columns: repeat(3, calc(33.33% - 42px/3));
                        .rectangle-result{
                        justify-self:center;
                         .product-image-container,.product-image{
                            height:180px;
                        }
                    }
                }
                @media (max-width: 925px) {
                    grid-template-columns: repeat(2, calc(50% - 21px/2));

                    .rectangle-result {
                        justify-self: center;

                        .product-image-container,
                        .product-image {
                            height: 180px;
                        }
                    }
                }
                @media (max-width: $smMaxWidth) {
                    grid-template-columns: repeat(1, 100%);
                    .rectangle-result{
                        margin: 0px 32px;
                        .product-image-container,.product-image{
                            height:200px;
                        }
                        justify-self:center;
                    }
                }
                @media (max-width: 625px) {
                    grid-template-columns: repeat(1, 100%);
                    .rectangle-result{
                        width: auto;
                    }
                }
                &.v2-circle {
                    display: flex;
                    justify-content: center;
                    column-gap: 5px;
                }
            }
            .lowes-list > .sk-hits {
                grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            }
            .filters {
                .filter--brands,
                .filter--brand,
                .filter--finish,
                .filter--subtype,
                .filter--finishFamily,
                .filter--finish2,
                .filter--favouriteV2,
                .filter--format,
                .filter--discountsV2,
                .filter--outletQuality,
                .filter--thickness,
                .filter--typeId {
                    .sk-panel__header {
                        font-family: $titleFont;
                    }
                    .sk-panel__content {
                        .sk-item-list__item {                            
                           
                            .sk-item-list-option {
                                display: flex;
                                align-items: center;
                                &:before {
                                    border: 4px solid black;
                                }
                            }

                            .sk-item-list-option__checkbox {
                                display: inline-block;
                                margin-right: 8px;
                                vertical-align: middle;
                                appearance: none;
                                -webkit-appearance: none;
                                padding: 8px;

                                border: none;
                                border-radius: 0;
                                background-color: transparent;
                                background-image: url('../img/components/checkbox-off.svg');
                                background-repeat: no-repeat;
                                background-size: contain;

                                opacity: 0.9;

                                cursor: pointer;

                                &:checked {
                                    background-image: url('../img/components/checkbox-on.svg');
                                }
                            }
                            .sk-item-list-option__count {
                                display: none;
                            }
                        }
                    }
                }
                .filter--type {
                    .sk-item-list-option__text{
                        top:70px !important;
                    }
                    .sk-panel__header {
                        font-family: $titleFont;
                    }
                    .sk-item-list__item {
                        &.is-active {
                            border:1px solid black;
                        }

                        width:60px;
                        height:60px;
                        margin: 10px 15px 35px;
                        font-size:35px;
                        line-height:30px;
                    } 
                }
                .filter--thickness {
                    .sk-panel__header {
                        font-family: $titleFont;
                    }
                    .sk-item-list-option__checkbox {
                        display: block;
                    }
                    .sk-panel__content {
                        .sk-item-list__item {
                            .sk-item-list-option__count {
                                display: block;
                                width: 30%;
                            }
                        }
                    }
                }
            }
        }
    }
}
.recently-view .rectangle-result:not(.mall-promo) {
    min-height: 435px;
}


.image-slide-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-size: cover;
    position: relative;
    span,
    a {
        position: relative;
        font-family: 'diagramm-light';
    }
    .slide-title {
        font-family: 'diagramm-medium';
        position: absolute;
        bottom: 60px;
        left: 30px;
        right: 30px;

        .title {
            font-size: 32px !important;
        }

        .subtitle {
            margin-top: -5px;
            font-size: 17px;
            font-weight: 200;
            font-family: 'diagramm-light';
        }
    }

    .slide-bt {
        position: absolute;
        bottom: 30px;
        left: 30px;
        right: 30px;

        .bt {
            font-weight: 600 !important;
            text-transform: uppercase;
            background: transparent;
            border: 1px solid white;
        }
    }
}
.v2 div[data-qa='options'] {
    margin-bottom: 0;
}



.slick-slide{
    .stock{
        .__react_component_tooltip.type-dark{
            max-width:140px;
        }
    }
}
.stock {
    .__react_component_tooltip.type-dark{
        background-color:$bluePrimary;
        max-width:240px;
        z-index:$layer60;
        &:after{
            border-top-color:$bluePrimary !important;
        }
    }
    .fa-info-circle{
        color:$bluePrimary;
    }
}

.catalog-title{
    margin-top: 32px;
    margin-bottom: 0;

    font-family: Diagramm;
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;

    @media (max-width: 775px){
        margin-bottom: -8px;
    }

    @media (max-width: 576px){
        padding: 0 16px;
    }
}

.sk-no-hits__info {
    color: $primaryColor;
    font-size: 16px;
    font-weight: 400;
}

.btn-view-labels-info {
    background-color: transparent;
    color: $normalblue;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    border: 0;
    padding: 0;

    &:hover {
        text-decoration: underline;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
        opacity: 1;
    }
    to {
        transform: translateX(100%);
        opacity: 0;
    }
}

.modal-dialog.modal-legend-of-labels {
    margin-right: 0;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    max-width: 620px;
    height: 100%;
    margin-top: 0 !important;

    .modal-content {
        height: 100%;
        max-height: 100%;
        background-color: #FAFBFB;

        hr {
            margin-bottom: 0.5rem;
            margin-top: 0.5rem;
        }


        .modal-header {
            padding-left: 24px;

            @media (max-width: $smMaxWidth) {
                padding-left: 16px;
                padding-right: 24px;
            }

            .modal-title {
                font-size: 28px;
                font-family: $titleFontNormal;
                line-height: 32px;
                font-weight: 500;
            }
        }

        .modal-body {
            padding-left: 24px;
            padding-right: 24px;
            padding-top: 24px;
            margin-bottom: 48px;

            @media (max-width: $smMaxWidth) {
                margin-bottom: 48px;
                padding-left: 16px;
                padding-right: 16px;
                padding-top: 16px;
            }

            p {
                margin: 0;
                color: #5E6578;
                line-height: 16px;
                font-size: 12px;
                font-weight: 300;
                padding-top: 8px;
            }

            a {
                color: $normalblue;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                
                &:hover {
                    text-decoration: underline !important;
                }
            }

            .legend__card {
                padding: 16px;
                background-color: $white;
                margin-bottom: 8px;

                &--title {
                    margin: 0;
                    padding-bottom: 16px;
                    text-align: left;
                    color: #5E6578;
                    font-size: 16px;
                    line-height: 20px;
                    font-family: $titleFontNormal;
                }

                &--content {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 16px;
                    padding: 8px;
                    align-items: start; /* Asegura que todos los elementos comiencen alineados */

                    /* Establece filas automáticas con una altura mínima */
                    grid-auto-rows: minmax(auto, auto); 


                    & > div {
                        display: flex;
                        flex-direction: column;
                        align-items: baseline;
                        text-align: start;
                    }

                    @media (max-width: $smMaxWidth) {
                        padding: 6px;
                    }
                }
            }
        }
    }
}

.modal-dialog.modal-legend-of-labels.enter {
    animation-name: slideIn;
}

.modal-dialog.modal-legend-of-labels.exit {
    animation-name: slideOut;
}
