.info_with_tool_tip {
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #232323;
  padding: 4px 8px 4px 8px;
  gap: 4px;
  border-radius: 4px;
  background: #E2E4E9;
  width: fit-content;
  margin-inline-end: 8px;

  span {
    display: flex;
    column-gap: 4px;
  }

  .tooltip {
    flex: 1;
    max-width: fit-content;
    background-color: $bluePrimary !important;
    color: $white !important;
    font-size: 12px !important;
    font-family: $infoFont !important;
    font-weight: 400 !important;
    opacity: 1 !important;

    &:after{
      border-bottom-color: $bluePrimary !important;
      border-top-color: $bluePrimary !important;
    }
  }




}