#section-lots {
    background: whitesmoke;

    td,
    th {
        border: none;
        padding: 8px 4px;
        vertical-align: middle;
    }

    th {
        border-bottom: 1px solid grey;
        padding-bottom: 16px;
    }

    td {
        border-bottom: 1px solid lightgrey;
    }

    table {
        border-collapse: separate;
        border-spacing: 0;
    }

    .table-header {
        position: sticky;
        top: 0;
        z-index: 1;
        background: whitesmoke;
        border-bottom: 1px solid black;
    }

    .outlet-indicator {
        background: #d66f00;
        border-radius: 2px;
        box-shadow: rgba($onyx, 0.3) 1px 1px 3px;
        color: $white;
        font-size: 11px;
        font-weight: bold;
        padding: 3px 5px 0;
        z-index: 1;
    }

    .grid-cell {
        .outlet-indicator {
            position: absolute;
            bottom: 0px;
            margin: 8px;
            width: auto;
        }
    }

    .alert {
        margin: 0 !important;
        border: none !important;
    }

    .bt {
        //border-radius: 4px;
        display: flex;
        align-items: center;
        border: 1px solid $primaryColor;
    }

    .table-row {
        overflow: auto;
        height: 500px;
        width: 100%;
        border-bottom: 1px solid lightgray;

        .bt {
            height: 3em;
            margin: 0;
        }

        .outlet-indicator {
            position: relative !important;
            width: fit-content;
            line-height: 1em;
            padding: 6px 10px;
        }
    }

    .lots__header__container {
        &--title {
            text-align: center;
            width: 100%;
            font-weight: 500;
            font-family: $titleFont;
            font-size: 24px;
            color: $primaryColor;
            line-height: normal;
            padding-bottom: 8px;
            padding-top: 16px;

        }

        &--price {
            text-align: center;
            width: 100%;
            font-family: $titleFont;
            color: $primaryColor;
            padding-bottom: 8px;
            
            span {
                font-size: 18px !important;
                font-weight: 500;
                line-height: normal;
            }
        }

        &--text {
            text-align: center;
            font-weight: 300;
            line-height: normal;
            font-size: 16px;
            color: $neutralText;
        }
    }

    .filters-container {
        margin-top: 32px;
        width: 100%;

        .search-box {
            height: 32px;
            border-radius: 0;
            border: 1px solid $primaryColor;
        }

        .bt {
            height: 32px;
            font-weight: 500 !important;
        }

        .section-title {
            margin: 30px 0 15px;
            font-weight: 500;
            font-size: 15px;
        }

        .filters {
            .dropdown-filters {
                padding-bottom: 22px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                @media (max-width: $mdMaxWidth) {
                    flex-direction: column;
                }

                @media (max-width: $smMaxWidth) {
                    padding-bottom: 16px;
                }

                .left-side {
                    display: flex;
                    align-items: center;
                    width: 90%;

                    @media (max-width: $mdMaxWidth) {
                        width: 100%;
                    }

                    @media (max-width: $smMaxWidth) {
                        flex-direction: column;
                    }
                }

                .filter {
                    width: 100%;
                    max-width: 218px;

                    &:nth-child(1n + 1) {
                        padding-right: 16px;
                    }

                    &:last-child {
                        padding-right: 0;
                    }

                    @media (max-width: $smMaxWidth) {
                        max-width: 100%;
                        padding-bottom: 16px;

                        &:nth-child(1n + 1) {
                            padding-right: 0;
                        }
                    }
                }

                .filter-clear-btn {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    button {
                        background-color: transparent;
                        border: 0;
                        color: $primaryColor;
                        font-size: 14px;
                        line-height: 16px;
                        font-weight: 500;
                        padding: 4px 0 4px 4px;

                        &:hover {
                            text-decoration: underline;
                        }
                    }

                    
                    @media (max-width: $mdMaxWidth) {
                        width: 100%;
                        justify-content: flex-start;
                        padding-top: 16px;
                    }

                    @media (max-width: $smMaxWidth) {
                        padding-top: 0;
                    }
                }
            }

            .search-bar-filters {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 16px;
                height: 36px;

                .lots-search-bar-desktop {
                    width: 100%;
                    display: block;
                    input {
                        height: 36px;
                        width: 100%;
                        max-width: 530px;
                        padding: 0 10px;
                        border: 1px solid $primaryColor;
                        color: $primaryColor;
                        font-size: 14px;
                        font-weight: 300;

                        &::placeholder {
                            font-size: 14px;
                            color: $primaryColor;
                        }
                    }

                    input[type='text'] {
                        background-color: $white;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 512 512'%3E%3Cpath fill='%23232323' d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'/%3E%3C/svg%3E");
                        background-repeat: no-repeat;
                        background-position: 97% 50%;
                    }

                    @media (max-width: $smMaxWidth){
                        display: none;
                    }
                }
                
                .dropdown-btn {
                    display: flex;
                    align-items: center;
                    justify-content: end;
                    width: 100%;

                    @media (max-width: $mdMaxWidth) {
                        width: 37%;
                    }

                    @media (max-width: $smMaxWidth) {
                        width: 100%;
                        justify-content: flex-start;
                    }

                    button.lots-filter-btn-download {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        max-width: 180px;
                        height: 36px;
                        padding: 8px 15px;
                        border: 1px solid $primaryColor;
                        background-color: #ffffff;
                        color: $primaryColor;
                        font-size: 14px;
                        font-weight: 300;
                        line-height: 22px;

                        i {
                            font-size: 16px;
                        }

                        &:hover {
                            background-color: $primaryColorHover;
                        }
                    }

                    .dropdown-menu {
                        padding-left: 8px;
                        padding-right: 8px;
                        border-radius: 0;
                        max-width: 180px;

                        button {
                            background-color: transparent;
                            color: $primaryColor;
                            font-size: 14px;
                            border: 0;
                            width: 100%;
                            padding: 8px 0;
                            text-align: left;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            .search-bar-filters-responsive {
                display: none;
            }

            @media (max-width: $smMaxWidth) {

                .search-bar-filters-responsive {
                    display: block;
                    padding-bottom: 16px;
                    width: 100%;
    
                    .lots-search-bar-responsive {
                        input {
                            height: 36px;
                            width: 100%;
                            padding: 0 10px;
                            border: 1px solid $primaryColor;
                            color: $primaryColor;
                            font-size: 14px;
                            font-weight: 300;
    
                            &::placeholder {
                                font-size: 14px;
                                color: $primaryColor;
                            }
                        }
                        
                        input[type='text'] {
                            background-color: $white;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 512 512'%3E%3Cpath fill='%23232323' d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'/%3E%3C/svg%3E");
                            background-repeat: no-repeat;
                            background-position: 98% 50%;
                        }
                    }
                }
            }
        }
    }

    .Select {
        font-weight: 400;
        border: 1px solid $primaryColor;
        border-radius: 0;
        background: #ffffff !important;
        font-size: 14px;
        color: $primaryColor;
    }

    .Select .Select-placeholder {
        font-size: 14px;
        color: $primaryColor;
        font-weight: 300;
        line-height: 36px;
    }

    .Select-arrow {
        border-color: $primaryColor transparent transparent;
    }

    .Select.is-open > .Select-control .Select-arrow {
        border-color: transparent transparent $primaryColor !important;
    }

    .lots-container {
        .lot-thumb {
            width: 100%;
            cursor: pointer;
        }

        .toolbar {
            padding-top: 32px;
            .lots_toolbar__qualities {
                display: flex;
                justify-content: flex-end;
                margin-bottom: -30px;
                min-height: 25px;
                & > span {
                    margin: 0 5px;
                    & > span:first-child {
                        font-weight: 400;
                    }
                }
            }

            .product-info {
                margin: 5px 0 0 20px;

                .product-color-name {
                    font-family: $titleFont;
                    font-size: 20px;
                    text-transform: capitalize;
                }

                .product-thickness {
                    display: inline-block;

                    .icon {
                        display: inline-block;
                        width: 15px;
                        height: 10px;
                        line-height: 10px;
                        border-left: 0.5px solid $onyx;
                        border-right: 0.5px solid $onyx;

                        div {
                            display: inline-block;
                            background: $onyx;
                            width: 100%;
                            vertical-align: middle;
                        }
                    }

                    .text {
                        display: inline-block;
                        font-size: 12px;
                        margin-left: 5px;
                    }
                }

                .product-finish {
                    display: inline-block;
                    margin-left: 8px;

                    .icon {
                        display: inline-block;
                        vertical-align: middle;

                        img {
                            height: 15px;
                        }
                    }

                    .text {
                        display: inline-block;
                        vertical-align: middle;

                        max-width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 12px;
                        margin-left: 5px;
                    }
                }
            }
        }

        .table {
            .rt-expander {
                padding-top: 30px;
            }

            .bundle-tag {
                border: 1px solid $thunder;
                border-radius: 4px;
                font-size: 11px;
                padding: 2px 4px;
                cursor: pointer;

                @media (max-width: $mdMaxWidth) {
                    margin-top: -9px;
                    display: inline-block;
                    text-align: center;

                    .count-container {
                        display: block;
                    }
                }

                .count-container {
                    .count {
                        background: $thunder;
                        color: $white;
                        border: 1px solid $thunder;
                        border-radius: 10px;
                        font-size: 10px;
                        padding: 0 3px;
                        margin-left: 3px;
                    }
                }
            }

            .promo-tag {
                background: red;
                border-radius: 4px;
                font-size: 11px;
                color: $white;
                padding: 2px 4px;
                cursor: pointer;
                text-transform: uppercase;

                @media (max-width: $mdMaxWidth) {
                    margin-top: -9px;
                    display: inline-block;
                    text-align: center;
                }
            }
        }
    }

    #lots-list {
        padding: 24px;
        background-color: #ffffff;
        margin-top: 32px;

        .lots__list__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 32px;

            &--counter {
                font-size: 16px;
                font-weight: 400;
                color: $primaryColor;
                width: 100%;

                @media (max-width: $smMaxWidth) {
                    padding-bottom: 16px;
                }
            }

            &--tabs {
                display: flex;
                flex-grow: 1;
                justify-content: end;
                width: 100%;

                .g-sel {
                    color: $primaryColor;
                    background-color: #ffffff;
                    border: none;
                    font-size: 12px;
                    font-weight: 500;
                    border: 1px solid $primaryColor;
                    height: 36px;
                    width: 174px;
                    padding-left: 32px;
                    padding-right: 32px;

                    &.active {
                        color: #ffffff;
                        background-color: $primaryColor;

                        &:hover {
                            background-color: $primaryColor;
                        }
                    }

                    &:hover {
                        background-color: $primaryColorHover;
                    }

                    i {
                        margin-right: 8px;
                    }

                    @media (max-width: $smMaxWidth) {
                        width: 100%;
                        padding-left: 8px;
                        padding-right: 8px;
                    }
                }
            }

            @media (max-width: $smMaxWidth) {
                flex-direction: column;
                align-items: baseline;
                width: 100%;
            }
        }

        .tooltip-container.magnifier-withouth-image {
            border-radius: 0 !important;
        }
        .box-btn-input-number {
            border-radius: 0 !important;
            border: 1px solid #e3e3e3 !important;
        }

        .lots__grid__container {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            --lots-custom-repeats: 4;
            grid-gap: 24px;
            
            .lots__grid__cell__container {
                display: flex;
                flex-direction: column;
                background-color: #ffffff;

                .lots__grid__cell__container__info {

                    &--content {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        flex-grow: 1;
                        padding: 0 16px;

                        .lot-name {
                            width: 100%;
                            padding-top: 16px;
                            h4 {
                                color: $primaryColor;
                                font-family: $titleFont;
                                margin-bottom: 0;
                                text-align: center;
                                font-weight: 500;
                                line-height: 20px;
                                font-size: 20px;
                            }

                            h6 {
                                color: $primaryColor;
                                font-family: $titleFont;
                                margin-bottom: 0;
                                text-align: center;
                                font-weight: 500;
                                font-size: 16px;
                                margin-top: 6px;
                            }

                            .is-latina-victoria-view-lots {
                                cursor: pointer;
                                color: $bluePrimary;

                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }

                        .quarry-block {
                            text-align: center;
                            padding-top: 6px;
                            span {
                                color: $primaryColor;
                                text-align: center;
                                font-size: 12px;
                                font-weight: 300;
                            }
                        }

                        .middle-details-content {
                            width: 100%;
                            display: flex;
                            justify-content: flex-start;
                            flex-direction: column;

                            .row__lot__info {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                width: 100%;
                                line-height: 18px;
                                padding-bottom: 6px;
    
                                &--key {
                                    width: 100%;
                                    span {
                                        color: $primaryColor;
                                        font-size: 14px;
                                        font-weight: 300;
                                    }
                                }
    
                                &--value {
                                    width: 100%;
                                    span {
                                        color: $thunder;
                                        font-size: 14px;
                                        font-weight: 400;
                                    }
                                }
    
                                &:last-child {
                                    padding-bottom: 0;
                                }
                            }
                        }

                        .row_lot__info__status {
                            display: flex;
                            align-items: center;
                            justify-content: baseline;
                            gap: 10px;
                            width: 100%;
                            color: $primaryColor;

                            .row_lot__info__status--icon {
                                i {
                                    font-size: 20px;
                                }
                            }

                            .row_lot__info__status--text {
                                font-size: 14px;

                                .main-text {
                                    font-weight: 700;
                                }

                                .categories {
                                    font-weight: 400;
                                }
                            }
                        }
                    }

                        &--buy {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 100%;
                        }

                        &--btn {
                            color: #ffffff;
                            border: 1px solid $primaryColor;
                            background-color: $primaryColor;
                            font-weight: 500;
                            font-size: 14px;
                            height: 30px;
                            width: 100px;
                            margin-inline: 8px;

                        &:hover {
                            color: $primaryColor;
                            background-color: $primaryColorHover;
                        }
                    }
                }

                .wrapper-product-image {
                    position: relative;
                    .magnifier {
                        .outlet__indicator {
                            position: absolute;
                            bottom: 10px;
                            left: 10px;                      
                        }
                    }
                }
            }

            @media (max-width: $smMaxWidth) {
                max-width: 100%;
            }
            
            @media (max-width: $lgMaxWidth) {
                grid-template-columns: repeat(3, 1fr);
                --lots-custom-repeats: 3;
            }

            @media (max-width: $lotGrid2Repeats) {
                grid-template-columns: repeat(2, 1fr);
                --lots-custom-repeats: 2;
            }

            @media (max-width: $lotGrid1Repeats) {
                $repeatLots:1;
                grid-template-columns: repeat(1, 1fr);
                --lots-custom-repeats: 1;
            }
        }

        @media (max-width: $smMaxWidth) {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    .magnifier {
        border-radius: 0 !important;
    }
    .table-header {
        background-color: #dbe4e9;
    }
    .bt.noselect.bt-size-medium.w-100.justify-content-center.square {
        border-radius: 0 !important;
        border: 1px solid $primaryColor !important;
    }

    .lots.container {
        @media (min-width: 576px) and (max-width: 991px) {
            max-width: 100%;
        }
    }
}

// Animate image modal zoom
.ril-outer {
    .ril-inner {
        .ril-image-current {
            transition: transform 0.5s;
        }
    }
    .ril-toolbar,
    .ril-caption-content {
        padding-right: 0;
        padding-left: 0;
        .bt {
            margin-right: 10px;
            line-height: 1em;
            border-radius: 5px;

            background: rgba($white, 0.1);

            font-size: 16px;
            color: rgba($white, 0.75);

            cursor: pointer;

            &:hover {
                background: rgba($white, 0.3);
            }

            &:active {
                background: rgba($white, 0.2);
            }
        }
    }
}
.bundle-content-modal {
    background: white;
    position: fixed;
    top: 20%;
    bottom: auto;
    right: 0;
    left: 0;
    margin: auto;
    width: 100%;
    max-width: 950px;
    z-index: $layer50;

    @media (max-width: $mdMaxWidth) {
        background: white;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }
    .rt-tbody {
        max-height: 40vh;

        overflow-y: auto;
    }
}

.ril__toolbar,
.ril__caption {
    @media screen and (min-width: $mdMaxWidth) {
        padding-left: 15% !important;
        padding-right: 15% !important;
    }

    .integer__input__number {
        .integer__input__number--max {
            span {
                color: #ffffff;
            }
        }
    }
}

.ril__navButtons {
    outline: none !important;
}

.ril-caption-content {
    .box-input-number {
        width: 100px;
    }
    .lot-button {
        font-size: 14px !important;
    }
    .box-btn-input-number {
        border: 1px solid $aluminum;
    }
    .input-number-other,
    .box-btn-input-number,
    .box-left,
    .box-text-input-number {
        color: $aluminum;
    }
    .box-left.disabled {
        color: $kinte !important;
    }
    .input-number-other.disabled {
        color: $kinte !important;
    }
}

.ril__toolbarLeftSide {
    width: 100% !important;
    padding-left: 20px;
    padding-right: 20px;
    .ril__toolbarItem {
        width: 100% !important;
    }
}
.ril__toolbarRightSide {
    display: flex;
    justify-content: flex-end;
}

.lot-viewer-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0; 
    .outlet-indicator {
        margin-left: 16px;
        background: #d66f00;
        border-radius: 2px;
        box-shadow: rgba($onyx, 0.3) 1px 1px 3px;
        color: $white;
        font-size: 11px;
        font-weight: bold;
        z-index: 1;
        width: min-content;
        width: -moz-fit-content;
        width: fit-content;
        line-height: 1em;
        padding: 6px 10px;
    }

    .lot-viewer-header-btns {
        display: flex;
        align-items: center;

        @media (max-width: $smMaxWidth) {
            .lot-viewer-download, .lot-viewer-copy-link {
                display: none;
            }
        }
    }

    @media (max-width: $smMaxWidth) {
        display: block;
    }
}

.lot__viewer__contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 14px;

    &--left {
        display: flex;
        align-items: baseline;
        gap: 16px;

        .row_lot__info__status {
            padding: 0 16px;
            display: flex;
            justify-content: baseline;
            gap: 10px;
            color: $white;
            border-left: 1px solid $white;
            border-right: 1px solid $white;

            .row_lot__info__status--icon {
                i {
                    font-size: 20px;
                }
            }

            .row_lot__info__status--text {
                font-size: 14px;

                .main-text {
                    font-weight: 700;
                }

                .categories {
                    font-weight: 400;
                }
            }
        }
    }

    &--right {
        display: flex;
        align-items: center;
        gap: 16px;

        .text-end {
            text-align: right;

            @media (max-width: $mdMaxWidth) {
                text-align: left;
            }
        }

        .button-quantity-block {
            display: flex;
            align-items: center;
            gap: 12px;

            .button-block {
                color: $primaryColor;
                background-color: $white;
                font-size: 14px;
                font-weight: 500;
                padding: 8px 12px;
                border: 0;
                display: flex;
                align-items: baseline;
                justify-content: center;

                &:hover {
                    background-color: #e4e4e4;
                }
            }

            @media (max-width: $smMaxWidth) {
                flex-direction: column;
                align-items: baseline;
            }
        }
    }

    @media (max-width: $mdMaxWidth) {
        flex-direction: column;
        align-items: baseline;
        padding: 0 16px;
    }
}

.ReactModalPortal {
    .bt.bt-buy-lot {
        font-size: 14px !important;
    }
}

.lot-row-image {
    position: relative;
    height: 60px;
    width: 60px;
    background-size: cover;
    background-position: center;
    background-color: #d8d8d8;
}
.tooltip-container {
    position: relative;
}

.tooltip-container .tooltip {
    font-size: 12px;
    visibility: hidden;
    width: 120px;
    background-color: grey;
    color: #fff;
    text-align: center;
    padding: 8px 4px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 10%;
}

.tooltip-container:hover .tooltip {
    visibility: visible;
}

.tooltip-container .tooltip::after {
    content: ' ';
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 20%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent grey transparent;
}

.tooltip-container .plus {
    left: 45% !important;
    width: 150px !important;
    font-size: 13px !important;
}

.integer__input__number__container {
    display: inline-block;

    .integer__input__number {
        display: flex;
        align-items: center;
        gap: 3px;

        &--down {
            width: 32px;
            height: 30px;

            button {
                background-color: #ffffff;
                border: 1px solid $primaryColor;
                width: 100%;
                height: 100%;
                outline: 0;
                text-align: center;
                color: $primaryColor;
                font-size: 16px;
                line-height: 0;

                i {
                    font-weight: 100;
                }

                &:hover {
                    background-color: $primaryColorHover;
                }

                &:disabled,
                &[disabled] {
                    background-color: #efefef;
                    cursor: not-allowed;
                    pointer-events: all;
                }
            }
        }

        &--number {
            width: 32px;
            height: 30px;

            input {
                width: 32px;
                height: 30px;
                border: 1px solid $primaryColor;
                background-color: #ffffff;
                outline: 0;
                text-align: center;
                font-size: 14px;
                color: $primaryColor;
                font-weight: 400;
                line-height: 0;

                &:disabled,
                &[disabled] {
                    background-color: #efefef;
                    cursor: not-allowed;
                    pointer-events: all;
                }
            }
        }

        &--up {
            width: 32px;
            height: 30px;

            button {
                background-color: #ffffff;
                border: 1px solid $primaryColor;
                width: 100%;
                height: 100%;
                outline: 0;
                text-align: center;
                color: $primaryColor;
                font-size: 16px;
                line-height: 0;

                i {
                    font-weight: 100;
                }

                &:hover {
                    background-color: $primaryColorHover;
                }

                &:disabled,
                &[disabled] {
                    background-color: #efefef;
                    cursor: not-allowed;
                    pointer-events: all;
                }
            }
        }

        &--max {
            margin-left: 8px;

            span {
                color: $primaryColor;
                font-weight: 400;
                font-size: 14px;
            }
        }
    }
}

.modal__lots__ids {
    .modal-content {
        width: 512px;
        .modal-header {
            padding: 16px 24px;
            border-bottom: 1px solid #a9aebc;
            align-items: center;

            .modal-title {
                color: $primaryColor;
                font-family: $titleFont;
                font-weight: 600;
            }
        }
        .modal-body {
            padding: 0;

            .ReactTable .rt-table .rt-thead .rt-tr .rt-th {
                padding: 12px 16px;
                background-color: #f3f5fc;
                text-align: left;
                color: #3e3e3c;
                font-size: 14px;
                font-weight: 400;
            }

            .ReactTable .rt-table .rt-tbody .rt-tr .rt-td {
                padding: 8px 24px;

                .lot_id_row {
                    font-size: 16px;
                    font-weight: 500;
                    color: black;
                }
            }
        }
        .modal-footer {
            display: none;
        }
    }
}