@import 'shake';
@import '~video-react/styles/scss/video-react';

// -----------------

@import 'variables';
@import 'mixins';
@import 'fonts';
@import 'global';
@import 'header';
@import 'footer';
@import 'floatingAlert';
@import 'catalog';
@import 'login';
@import 'productdetail';
@import 'profile';
@import 'checkout';
@import 'stepper';
@import 'kpis';
@import 'impersonate';
@import 'home';
@import 'segmentation';
@import 'lots';
@import 'tables_qty';
@import 'languageselector';
@import 'unassigned-orders';
@import 'promotions';
@import 'promotions_templates';
@import 'cut_to_size';
@import 'lowes';
@import 'mktAdminProductsMax';
@import 'orderHistory';
@import 'newOrderHistory';
@import 'walkthrough';
@import 'preOrderHistory';
@import 'loader';
@import 'productsSlider';
@import 'rocket_banner_promotion';
//components
@import 'editOrder';
@import 'components/global/InputNumber';
@import 'components/global/GoBack';
@import 'components/global/RectangleResult';
@import 'components/global/RectangleResultV2';
@import 'components/global/AddFavourite';
@import 'components/global/CartLines';
@import 'components/global/Button';
@import 'components/global/ReactTable';
@import 'components/global/SearchBar';
@import 'components/global/TipModal';
@import 'components/global/Pagination';
@import 'components/header/TopRibbon';
@import 'components/catalog/Results';
@import 'components/catalog/BannerResultV2';
@import 'components/global/ProductImage';
@import 'components/global/PedidosRecurrentesModal';
@import 'components/global/InfoBox';
@import 'components/global/banner_silestone';
@import 'components/landing/landing-manager';
@import 'components/landing/landing-creation';
@import 'components/landing/landing-templates';
@import 'components/weights/Weights';
@import 'components/productinfotabs';
@import 'components/configurable-baths/configurable-baths';
@import 'components/global/InfoWithToolTip';

@import 'react-select';

body {
    font-family: $infoFont;
    font-weight: 300;
    color: $thunder;
    &.noscroll {
        @media (max-width: $mdMaxWidth) {
            position: fixed;
            overflow: hidden;
        }
    }
}

a {
    color: inherit;
    text-decoration: none !important;

    &:hover {
        color: inherit;
    }
}
