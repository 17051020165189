/*
	COLORS
*/
// Blacks & dark grays
$dark: #0a0a0a;
$onyx: #1a1a1a;
$kunta: #2b2b2b;
$kinte: #3c3c3c;
$thunder: #4d4d4d;
$we: #5A5A5A;
$gray: #808080;
$newOnyx: #232323;
$aluminum: #999999;
$background: #f2f1f7;
$backgroundV2: #F9F9F9;
// Soft grays
$iron: #cccccc;
$mercury: #e6e6e6;
$smoke: #f6f6f6;
// Blue grays
$loblolly: #bccbd2;
$solitude: #e8edef;
// Colors
$blue: #9BC2C7;
$bluePrimary: #2B49A2;
$lightAqua: #b4dbe4;
$green: #86BAAE;
// Light colors
$lightBlue: #D1E3E6;
$lightGreen: #C3E0DA;
// Ok Green
$greenOk: #93c293;
// White
$white: #ffffff;
$sky: #EBF5F8;
$greenSuccess: #36A596;

$shadow: rgba(0,0,0,0.25);

$lightWhite: #efefef;
$lightGray: #e3e3e3;
$mariBlue: #eaf4f2;
$warning: #ffeeba;

//V2

$primaryColor: #232323;
$primaryColorDisable: #D8D8D8;
$primaryColorHover: #DBE4E9;
$SecondaryColor: #FFFFFF;
$SecondaryColorDisable: #FFFFFF;
$SecondaryColorHover: #DBE4E9;
$TertiaryColor: #DBE4E9;
$TertiaryTextColor: #7D7D7D;
$whiteColor: #FFFFFF;
$backgroundColor: #F9F9F9;
$progressBar: #9BC2C7;
$alertColor: #FF6805;
$errorColor: #FF5F6D;
$errorProgressBar: #CE2F1A;
$errorBackground: #FBDFDC;
$disableLightColor: #D8D8D8;
$disableHeavyColor: #9B9B9B;
$normalblue: #365BCA;
$discontinuedIndicatorBg: #3C898A;
$outletIndicatorBg: #C9742A;
$promoIndicatorBg: #ff0000;
$warning: #ffeeba;
$neutralText: #4A4A4A;
$secondNeutralText: #646464;
$stockSuccessAvailable: #00A000;
$primaryGray: #323232;
$softGray: #C7C7C7;
$tertiaryText: #7D7D7D;
$brandNewBlue : #365BCA;

/*
	RESPONSIVE BREAKPOINTS
*/
$xxsMaxWidth: 361px; //Iphone SE portrait / Galaxy S5
$xsMaxWidth: 575px;
$smMaxWidth: 767px;
$mdMinWidth: 768px;
$mdMaxWidth: 991px; //Tablet
$lgMaxWidth: 1199px;
$lgMinWidth: 1200px;
$xlgMaxWidth: 1399px;
$headerHeight: 96px;

$lotGrid2Repeats: 898px;
$lotGrid1Repeats: 595px;


/*
	FONTS
*/
$titleFont: 'diagramm-medium';
$titleFontRegular: 'diagramm-regular';
$titleFontNormal: 'diagramm';
$infoFont: 'Roboto', Helvetica, Arial, sans-serif;

/*
	LAYERS
*/
//layer 0 is no z-index
$layer10: 100;
$layer15: 150;
$layer20: 200;
$layer25: 250;
$layer30: 300;
$layer40: 400;
$layer50: 500;
$layer60: 600;

/*
	MARGINS & PADDINGS
*/
$headingBorderRadius: 5px;

/*
	TRANSITIONS FUNCTIONS
*/
$ease: cubic-bezier(0.25, 0.46, 0.45, 0.94);

:export {
  repeatLots: 4;
}